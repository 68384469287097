var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { WidgetContainer, WidgetTitle, WidgetP, WidgetSubtitle, WidgetButton } from "./styles";
import styled from "styled-components";
var ReportImage = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
export var Reports = function (props) {
    return (React.createElement(WidgetContainer, null,
        React.createElement(WidgetTitle, null, "Reports"),
        React.createElement(WidgetP, null, "Our archive of global luxury industry trends, reports and white papers"),
        React.createElement(ReportImage, { src: "https://media.dailyjingjing.com/assets/nft-report-tall.jpeg", alt: "Jing Daily Report" }),
        React.createElement(WidgetSubtitle, null, "The NFT Potential for Luxury"),
        React.createElement(WidgetButton, { href: "/report/jing-daily-insight-series-the-nft-potential-for-luxury/" }, "Download Now")));
};
var templateObject_1;
