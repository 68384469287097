var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { WidgetContainer, WidgetTitle, WidgetP, WidgetButton, WidgetSerifSubtitle } from "./styles";
import styled from "styled-components";
var Table = styled.table(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-collapse: collapse;\n  border-spacing: 0;\n  width: 100%;\n"], ["\n  border-collapse: collapse;\n  border-spacing: 0;\n  width: 100%;\n"])));
var THead = styled.thead(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 15px;\n  font-weight: 600;\n  text-align: center;\n"], ["\n  font-size: 15px;\n  font-weight: 600;\n  text-align: center;\n"])));
var TBody = styled.tbody(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 21px;\n  text-align: center;\n"], ["\n  font-size: 21px;\n  text-align: center;\n"])));
var TD = styled.td(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: 5px 10px;\n  font-weight: 600;\n  color: ", ";\n"], ["\n  padding: 5px 10px;\n  font-weight: 600;\n  color: ", ";\n"])), function (props) { return (props.status === "green" ? "#198754" : props.status === "red" ? "#dc3545" : "#000"); });
export var JingIndex = function (props) {
    return (React.createElement(WidgetContainer, null,
        React.createElement(WidgetTitle, null, "Jing Daily KraneShares China Global Luxury Index"),
        React.createElement(WidgetP, null, "Track the global market performance of the luxury sector in China. With current news updates, share prices, and stock market data based on Chinese consumer interest, this index monitors the overall health within the market."),
        React.createElement(WidgetSerifSubtitle, null, "Daily Returns: 05/31/2021"),
        React.createElement(Table, null,
            React.createElement(THead, null,
                React.createElement("tr", null,
                    React.createElement("td", null, "Index Level"),
                    React.createElement("td", null, "Daily Change"),
                    React.createElement("td", null, "% Change"))),
            React.createElement(TBody, null,
                React.createElement("tr", null,
                    React.createElement(TD, { status: "unch" }, "349.80"),
                    React.createElement(TD, { status: "red" }, "-$1.81"),
                    React.createElement(TD, { status: "red" }, "0.52%")))),
        React.createElement(Table, null,
            React.createElement(THead, null,
                React.createElement("tr", null,
                    React.createElement("td", null, "LVMH"),
                    React.createElement("td", null, "Kering"),
                    React.createElement("td", null, "Richemont"))),
            React.createElement(TBody, null,
                React.createElement("tr", null,
                    React.createElement(TD, { status: "green" }, "+$0.93"),
                    React.createElement(TD, { status: "green" }, "-$0.77"),
                    React.createElement(TD, { status: "green" }, "$0.42")))),
        React.createElement(WidgetButton, { href: "/china-luxury-index/" }, "View Index")));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
