var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import styled from "styled-components";
import { Colors } from "@styles";
var ContainerWrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 18px 18px 23px;\n  margin: 25px 0;\n  border: 1px solid #dcdcdc;\n  background-color: ", ";\n  color: ", ";\n  .row {\n    flex: 1;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 18px 18px 23px;\n  margin: 25px 0;\n  border: 1px solid #dcdcdc;\n  background-color: ", ";\n  color: ", ";\n  .row {\n    flex: 1;\n  }\n"])), Colors.medGrey, Colors.white);
var Details = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-right: 20px;\n"], ["\n  margin-right: 20px;\n"])));
var Title = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 22px;\n  font-weight: 700;\n  margin: 0;\n  line-height: 1.2;\n"], ["\n  font-size: 22px;\n  font-weight: 700;\n  margin: 0;\n  line-height: 1.2;\n"])));
var Subtitle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 14px;\n  margin-top: 4px;\n  font-weight: 700;\n"], ["\n  font-size: 14px;\n  margin-top: 4px;\n  font-weight: 700;\n"])));
var FormWrap = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
var FormContentWrap = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n"])));
var EmailInput = styled.input(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin: 6px 0 0;\n  appearance: none;\n  width: 100%;\n  padding: 0.75em 10px;\n  font-size: 16px;\n  font-weight: 500;\n  border: none;\n  border-bottom: 0;\n  border-radius: 0;\n  background: ", ";\n  font-family: \"Quasimoda\", tahoma;\n"], ["\n  margin: 6px 0 0;\n  appearance: none;\n  width: 100%;\n  padding: 0.75em 10px;\n  font-size: 16px;\n  font-weight: 500;\n  border: none;\n  border-bottom: 0;\n  border-radius: 0;\n  background: ", ";\n  font-family: \"Quasimoda\", tahoma;\n"])), Colors.white);
var Submit = styled.input(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  appearance: none;\n  box-sizing: border-box;\n  width: 100%;\n  display: block;\n  border: none;\n  outline: none;\n  margin-top: 20px;\n  background-color: ", ";\n  color: #fff;\n  font-weight: 600;\n  border-radius: 3px;\n  padding: 10px 16px;\n  text-align: center;\n  font-size: 16px;\n  line-height: 1.45;\n  margin-top: 6px;\n  cursor: pointer;\n  font-family: \"Quasimoda\", tahoma;\n"], ["\n  appearance: none;\n  box-sizing: border-box;\n  width: 100%;\n  display: block;\n  border: none;\n  outline: none;\n  margin-top: 20px;\n  background-color: ", ";\n  color: #fff;\n  font-weight: 600;\n  border-radius: 3px;\n  padding: 10px 16px;\n  text-align: center;\n  font-size: 16px;\n  line-height: 1.45;\n  margin-top: 6px;\n  cursor: pointer;\n  font-family: \"Quasimoda\", tahoma;\n"])), Colors.blue);
export var FullNewsletterSignUp = function (props) {
    return (React.createElement("div", { className: "container d-none d-sm-flex" },
        React.createElement(ContainerWrap, { className: "row align-items-center" },
            React.createElement("div", { className: "col-lg-6" },
                React.createElement(Details, { type: props.type },
                    props.type === "homepage" ? (React.createElement(Title, null,
                        "Start your day with The ",
                        React.createElement("em", null, "Jing Daily Newsletter"))) : (React.createElement(Title, null, "Stay on top of market insights from China that matter")),
                    React.createElement(Subtitle, null, props.type === "homepage"
                        ? "Insights, analysis, and breaking news direct to your inbox."
                        : "Subscribe to our newsletter for a look ahead to what drives the world’s biggest cultural consumers."))),
            React.createElement("div", { className: "col-lg-6" },
                React.createElement(FormWrap, { type: props.type },
                    React.createElement("form", { action: "//jingdaily.us1.list-manage.com/subscribe/post?u=555a04b48e1f20aecf5db4d61&id=8dec01cd8d", method: "post", className: "validate sidebar-newsletter-signup js-track-submit", target: "_blank", noValidate: true },
                        React.createElement("div", { className: "row align-items-start" },
                            React.createElement("div", { className: "col-12 col-md-8 col-lg-9" },
                                React.createElement(EmailInput, { type: "email", name: "EMAIL", id: "mce-EMAIL-Full", placeholder: "Your Email" })),
                            React.createElement("div", { className: "col-12 col-md-4 col-lg-3" },
                                React.createElement(Submit, { type: "submit", value: "Subscribe", name: "subscribe", id: "mc-embedded-subscribe-Full" })))))))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
