var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var breakpoints = {
    xxs: {
        min: 0,
        max: 350
    },
    xs: {
        min: 0,
        max: 699
    },
    iPhone8Plus: {
        min: 414,
        max: 736
    },
    sm: {
        min: 700,
        max: 899
    },
    smd: {
        min: 900,
        max: 1024
    },
    md: {
        min: 900,
        max: 1199
    },
    sMbp: {
        min: 1024,
        max: 1280
    },
    macbook: {
        min: 1200,
        max: 1440
    },
    lg: {
        min: 1200,
        max: 1799
    },
    xl: {
        min: 1800
    },
    minXxs: {
        min: 350
    },
    minXs: {
        min: 426
    },
    minSm: {
        min: 769
    },
    minMd: {
        min: 1025
    },
    minLg: {
        min: 1441
    },
    maxXxs: {
        max: 350
    },
    maxXs: {
        max: 426
    },
    maxSm: {
        max: 769
    },
    maxMd: {
        max: 1025
    },
    maxLg: {
        max: 1441
    }
};
var mq = Object.keys(breakpoints).reduce(function (bps, bp) {
    var _a;
    return (__assign(__assign({}, bps), (_a = {}, _a[bp] = "@media only screen\n    " + (breakpoints[bp].min ? " and (min-width: " + breakpoints[bp].min + "px)" : "") + "\n    " + (breakpoints[bp].max ? " and (max-width: " + breakpoints[bp].max + "px)" : "") + "\n  ", _a)));
}, {});
var PORTRAIT = "@media (hover: none) and (pointer: coarse) and (orientation:portrait) and (min-width: 770px)";
var LANDSCAPE = "@media (hover: none) and (pointer: coarse) and (orientation:landscape) and (min-width: 1194px)";
var SMALL = "@media (hover: hover) and (max-width: 1200px), (hover: hover) and (max-height: 700px)";
var BOOK_SMALL = "@media (hover: hover) and (max-height: 700px)";
var SMBP = "@media (max-width: 1280px) and (min-width: 1201px) and (max-height: 699px)";
export { breakpoints, mq, PORTRAIT, SMALL, LANDSCAPE, SMBP, BOOK_SMALL };
