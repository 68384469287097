var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Route, Switch } from "react-router";
import { QueryClient, QueryClientProvider } from "react-query";
import { Routes } from "./routes/index";
var queryClient = new QueryClient();
export var App = function () {
    // @ts-expect-error
    var pageData = typeof window !== "undefined" ? window.__ROUTE_DATA__ : {};
    var staticContext = {
        pageData: pageData
    };
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement(Switch, null, Routes.map(function (route) {
            var componentName = route.componentName, routeProps = __rest(route, ["componentName"]);
            if (typeof window === "undefined") {
                routeProps.component = componentName;
            }
            else {
                routeProps.render = function () {
                    var Component = route.componentName ? route.componentName : route.component;
                    return Object.keys(pageData).length > 0 ? (React.createElement(Component, { staticContext: staticContext, key: route.path })) : (React.createElement(Component, { key: route.path }));
                };
            }
            return React.createElement(Route, __assign({ key: route.path }, routeProps));
        }))));
};
