var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import styled from "styled-components";
import { Colors } from "@styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faTwitter, faLinkedinIn, faWeixin } from "@fortawesome/free-brands-svg-icons";
var ContainerWrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  padding: 12px 0;\n  text-transform: uppercase;\n  font-size: 14px;\n  .row {\n    flex: 1;\n  }\n"], ["\n  background-color: ", ";\n  color: ", ";\n  padding: 12px 0;\n  text-transform: uppercase;\n  font-size: 14px;\n  .row {\n    flex: 1;\n  }\n"])), Colors.lightGrey, Colors.medGrey);
var SocialLinksWrap = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  text-transform: uppercase;\n  font-size: 14px;\n"], ["\n  text-transform: uppercase;\n  font-size: 14px;\n"])));
var SocialLink = styled.a(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-left: 12px;\n  text-decoration: none;\n  color: #6a6a6a;\n"], ["\n  margin-left: 12px;\n  text-decoration: none;\n  color: #6a6a6a;\n"])));
var MetaLinks = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 13px;\n  font-weight: 500;\n  line-height: 1.45;\n  text-align: right;\n"], ["\n  font-size: 13px;\n  font-weight: 500;\n  line-height: 1.45;\n  text-align: right;\n"])));
var MetaLink = styled.a(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-left: 12px;\n  text-decoration: none;\n  color: #6a6a6a;\n\n  &:hover {\n    color: #000;\n  }\n"], ["\n  margin-left: 12px;\n  text-decoration: none;\n  color: #6a6a6a;\n\n  &:hover {\n    color: #000;\n  }\n"])));
export var TopBar = function () {
    return (React.createElement(ContainerWrap, { className: "container-fluid d-none d-md-block" },
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "row" },
                React.createElement(SocialLinksWrap, { className: "col-6" },
                    "Follow Us",
                    React.createElement(SocialLink, { href: "https://www.facebook.com/jingdaily/", rel: "noreferrer", target: "_blank" },
                        React.createElement(FontAwesomeIcon, { icon: faFacebookF })),
                    React.createElement(SocialLink, { href: "https://twitter.com/JingDaily", rel: "noreferrer", target: "_blank" },
                        React.createElement(FontAwesomeIcon, { icon: faTwitter })),
                    React.createElement(SocialLink, { href: "https://www.linkedin.com/company/jing-daily", rel: "noreferrer", target: "_blank" },
                        React.createElement(FontAwesomeIcon, { icon: faLinkedinIn })),
                    React.createElement(SocialLink, { href: "weixin://dl/chat?Jing_Daily_China", rel: "noreferrer", target: "_blank" },
                        React.createElement(FontAwesomeIcon, { icon: faWeixin }))),
                React.createElement(MetaLinks, { className: "col-6" },
                    React.createElement(MetaLink, { href: "/advertise" }, "Advertise"),
                    React.createElement(MetaLink, { href: "/about" }, "About"),
                    React.createElement(MetaLink, { href: "/events" }, "Events"))))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
