var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { TopBar, Nav, Footer, NewsletterSignUp, WidgetButton } from "@shared";
import { AppContainer } from "@containers";
import { mq } from "@styles";
import { createMarkup } from "@utils";
var MQ = mq;
var ContainerWrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  margin: 0 auto;\n\n  .row {\n    max-width: calc(100vw - 1.5rem);\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  margin: 0 auto;\n\n  .row {\n    max-width: calc(100vw - 1.5rem);\n  }\n"])));
var BottomWrap = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  h2 {\n    margin: 20px 0;\n  }\n"], ["\n  h2 {\n    margin: 20px 0;\n  }\n"])));
var IFrame = styled.iframe(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  border: 0;\n  overflow-y: hidden;\n  height: 475px;\n\n  ", " {\n    height: 555px;\n  }\n"], ["\n  width: 100%;\n  border: 0;\n  overflow-y: hidden;\n  height: 475px;\n\n  ", " {\n    height: 555px;\n  }\n"])), MQ.xs);
var KraneImg = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  max-width: 120px;\n"], ["\n  max-width: 120px;\n"])));
var JingLogo = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: 700;\n"], ["\n  font-weight: 700;\n"])));
var RightTopWrap = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  h2 {\n    margin: 0 0 20px;\n  }\n"], ["\n  h2 {\n    margin: 0 0 20px;\n  }\n"])));
var KraneIndexTable = styled.table(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  border: 2px solid #aaa;\n  width: 100%;\n  margin-bottom: 40px;\n\n  thead {\n    text-align: center;\n\n    tr td {\n      font-weight: 600;\n      font-size: 16px;\n      padding-top: 10px;\n    }\n  }\n\n  tbody {\n    font-size: 21px;\n    font-weight: 600;\n    text-align: center;\n  }\n"], ["\n  border: 2px solid #aaa;\n  width: 100%;\n  margin-bottom: 40px;\n\n  thead {\n    text-align: center;\n\n    tr td {\n      font-weight: 600;\n      font-size: 16px;\n      padding-top: 10px;\n    }\n  }\n\n  tbody {\n    font-size: 21px;\n    font-weight: 600;\n    text-align: center;\n  }\n"])));
var TickerTable = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  overflow-x: auto;\n\n  table {\n    width: 100%;\n    vertical-align: top;\n    border-collapse: collapse;\n    border-spacing: 0;\n\n    thead td {\n      font-weight: 600;\n      font-size: 16px;\n      vertical-align: middle;\n      border-bottom: 1px solid #807d3b;\n      padding: 10px;\n    }\n\n    td {\n      vertical-align: top;\n      padding: 10px;\n    }\n  }\n"], ["\n  overflow-x: auto;\n\n  table {\n    width: 100%;\n    vertical-align: top;\n    border-collapse: collapse;\n    border-spacing: 0;\n\n    thead td {\n      font-weight: 600;\n      font-size: 16px;\n      vertical-align: middle;\n      border-bottom: 1px solid #807d3b;\n      padding: 10px;\n    }\n\n    td {\n      vertical-align: top;\n      padding: 10px;\n    }\n  }\n"])));
var TickerCloseTD = styled.td(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), function (props) { return (props.status === "green" ? "#198754" : props.status === "red" ? "#dc3545" : "#000"); });
var TitleTD = styled.td(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-size: 16px;\n  font-weight: 700;\n  vertical-align: top;\n  text-transform: uppercase;\n  margin-bottom: 0 !important;\n"], ["\n  font-size: 16px;\n  font-weight: 700;\n  vertical-align: top;\n  text-transform: uppercase;\n  margin-bottom: 0 !important;\n"])));
var TickerDataTable = styled.table(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  font-size: 14px;\n  width: 80%;\n  margin-bottom: 20px;\n  border-collapse: collapse;\n  border-spacing: 0;\n  text-transform: uppercase;\n\n  tbody tr td:first-child {\n    padding-left: 0;\n    padding-bottom: 0;\n    font-weight: 500;\n  }\n\n  tbody tr td:last-child {\n    padding-right: 0;\n    padding-bottom: 0;\n    text-align: right;\n  }\n"], ["\n  font-size: 14px;\n  width: 80%;\n  margin-bottom: 20px;\n  border-collapse: collapse;\n  border-spacing: 0;\n  text-transform: uppercase;\n\n  tbody tr td:first-child {\n    padding-left: 0;\n    padding-bottom: 0;\n    font-weight: 500;\n  }\n\n  tbody tr td:last-child {\n    padding-right: 0;\n    padding-bottom: 0;\n    text-align: right;\n  }\n"])));
var MobileArticleTable = styled.table(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  font-size: 14px;\n  width: 100%;\n  margin-bottom: 20px;\n"], ["\n  font-size: 14px;\n  width: 100%;\n  margin-bottom: 20px;\n"])));
var ArticleTD = styled.td(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  vertical-align: top;\n  border-bottom: 1px solid #807d3b;\n  padding: 10px;\n  background: #efefef;\n  text-align: left;\n  font-size: 22px;\n\n  a {\n    text-decoration: none;\n    font-weight: 600;\n    color: #000;\n  }\n\n  ", " {\n    font-size: 16px;\n  }\n"], ["\n  vertical-align: top;\n  border-bottom: 1px solid #807d3b;\n  padding: 10px;\n  background: #efefef;\n  text-align: left;\n  font-size: 22px;\n\n  a {\n    text-decoration: none;\n    font-weight: 600;\n    color: #000;\n  }\n\n  ", " {\n    font-size: 16px;\n  }\n"])), MQ.xs);
var ResponsiveTD = styled.td(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  ", " {\n    display: none;\n  }\n"], ["\n  ", " {\n    display: none;\n  }\n"])), MQ.xs);
var ToggleTable = styled.a(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  width: 100%;\n  padding: 20px;\n  font-size: 22px;\n  font-weight: 700;\n  display: block;\n  margin-top: 20px;\n  background-color: #000;\n  color: #fff;\n  text-decoration: none;\n  border-radius: 3px;\n  text-align: center;\n  line-height: 1.45;\n"], ["\n  width: 100%;\n  padding: 20px;\n  font-size: 22px;\n  font-weight: 700;\n  display: block;\n  margin-top: 20px;\n  background-color: #000;\n  color: #fff;\n  text-decoration: none;\n  border-radius: 3px;\n  text-align: center;\n  line-height: 1.45;\n"])));
var TickerRow = styled.tr(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (props) { return (props.show ? "" : "display: none;"); });
var TD = styled.td(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  padding: 5px 10px;\n  color: ", ";\n"], ["\n  padding: 5px 10px;\n  color: ", ";\n"])), function (props) { return (props.status === "green" ? "#198754" : props.status === "red" ? "#dc3545" : "#000"); });
var LegalWrap = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  width: 60%;\n  margin: 0 auto;\n\n  ", " {\n    width: 100%;\n  }\n"], ["\n  width: 60%;\n  margin: 0 auto;\n\n  ", " {\n    width: 100%;\n  }\n"])), MQ.xs);
export var LuxuryIndexPage = function (props) {
    var _a, _b, _c, _d, _e, _f;
    var _g = useState(((_c = (_b = (_a = props === null || props === void 0 ? void 0 : props.staticContext) === null || _a === void 0 ? void 0 : _a.pageData) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.tickers) || []), data = _g[0], setData = _g[1];
    var _h = useState(((_f = (_e = (_d = props === null || props === void 0 ? void 0 : props.staticContext) === null || _d === void 0 ? void 0 : _d.pageData) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.index) || {}), index = _h[0], setIndex = _h[1];
    var _j = useState(false), toggled = _j[0], setToggled = _j[1];
    var _k = useState("Show All Constituents"), toggleText = _k[0], setToggleText = _k[1];
    var ts = Math.floor(Date.now() / 1000);
    var indexDateParts = (index === null || index === void 0 ? void 0 : index.date) ? index.date.split("-") : [];
    var dailyReturnsDate = indexDateParts.length > 0 ? indexDateParts[1] + "/" + indexDateParts[2] + "/" + indexDateParts[0] : "";
    var indexChange = (index === null || index === void 0 ? void 0 : index.index_level_change) ? parseFloat(index.index_level_change) : "";
    var indexChangeStatus = indexChange < 0.0 ? "red" : "green";
    var indexPercentChange = (index === null || index === void 0 ? void 0 : index.index_prct_change) ? index.index_prct_change : 0.0;
    var indexPercentChangeStatus = indexPercentChange < 0.0 ? "red" : "green";
    return (React.createElement(AppContainer, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, "Jing Daily \u2014 The business of luxury in China")),
        React.createElement(TopBar, null),
        React.createElement(Nav, null),
        React.createElement(ContainerWrap, { className: "container" },
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-md-8" },
                    React.createElement(IFrame, { src: "https://kraneshares.com/china-luxury?t=" + ts, scrolling: "no" }),
                    React.createElement("h3", null,
                        "In Partnership with",
                        " ",
                        React.createElement(KraneImg, { src: "https://jingdaily.com/wp-content/themes/jingdaily/img/kraneshares-logo.png", alt: "Krane Shares Logo" }),
                        " ",
                        "and ",
                        React.createElement(JingLogo, null, "Jing Finance"))),
                React.createElement(RightTopWrap, { className: "col-md-4" },
                    React.createElement("h2", null,
                        "Daily Returns: ",
                        dailyReturnsDate),
                    React.createElement(KraneIndexTable, null,
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                React.createElement("td", null, "Index Level"),
                                React.createElement("td", null, "Daily Change"),
                                React.createElement("td", null, "% Change"))),
                        React.createElement("tbody", null,
                            React.createElement("tr", null,
                                React.createElement(TD, { status: "unch" }, "350.87"),
                                React.createElement(TD, { status: indexChangeStatus }, indexChange),
                                React.createElement(TD, { status: indexPercentChangeStatus }, indexPercentChange)))),
                    React.createElement(NewsletterSignUp, { type: "finance" })),
                React.createElement(BottomWrap, { className: "col-12" },
                    React.createElement("div", null,
                        React.createElement("h3", null, "Introduction"),
                        React.createElement("p", null, "The Jing Daily KraneShares China Global Luxury Index tracks the global market performance of the luxury sector, with an emphasis on luxury business in China. We developed the Index to empower global brands and investors to effortlessly identify revenue-generating opportunities driven by Chinese luxury consumer demand. With current news updates, share values, and stock market data based on Chinese consumer interest, the Index monitors the overall health within the market."),
                        React.createElement("p", null,
                            "The Index is a joint collaboration between leading B2B publication ",
                            React.createElement("em", null, "Jing Daily"),
                            " and investment management firm KraneShares. Merging ",
                            React.createElement("em", null, "Jing Daily"),
                            "\u2019s on-the-ground insight with Krane Shares\u2019 investment expertise, the Index serves as an unparalleled resource for investors, analysts, reporters, and the luxury industry at large."),
                        React.createElement("p", null,
                            React.createElement("strong", null, "See Index Methodology Below Table."))),
                    data && data.length > 0 && (React.createElement(TickerTable, null,
                        React.createElement("table", null,
                            React.createElement("thead", null,
                                React.createElement("tr", null,
                                    React.createElement("td", null, "Rank"),
                                    React.createElement("td", { style: { minWidth: 280, padding: 0 } }, "Company"),
                                    React.createElement(ResponsiveTD, { colSpan: 3 }, "Jing's Coverage"))),
                            React.createElement("tbody", null, data.map(function (row, index) {
                                var closeStatus = row.change < 0.0 ? "red" : "green";
                                var close = row.change < 0.0 ? row.change : "+" + row.change;
                                return row.index_position ? (React.createElement(TickerRow, { key: row.company, show: index <= 19 || toggled },
                                    React.createElement("td", null, row.index_position),
                                    React.createElement(TitleTD, null,
                                        row.company,
                                        React.createElement(TickerDataTable, null,
                                            React.createElement("tbody", null,
                                                React.createElement("tr", null,
                                                    React.createElement("td", null, "Index Weight"),
                                                    React.createElement("td", null, row.index_weight)),
                                                React.createElement("tr", null,
                                                    React.createElement("td", null, "Ticker"),
                                                    React.createElement("td", null, row.ticker)),
                                                React.createElement("tr", null,
                                                    React.createElement("td", null, "Change"),
                                                    React.createElement(TickerCloseTD, { status: closeStatus }, close)),
                                                React.createElement("tr", null,
                                                    React.createElement("td", null, "Prev. Close"),
                                                    React.createElement("td", null, row.live_price)),
                                                React.createElement("tr", null,
                                                    React.createElement("td", null, "52 Wk. Range"),
                                                    React.createElement("td", null, row.wk_52_price)),
                                                React.createElement("tr", null,
                                                    React.createElement("td", null, "Market Cap"),
                                                    React.createElement("td", null, row.market_cap)))),
                                        React.createElement("div", { className: "d-sm-none" },
                                            React.createElement("span", null, "Jing's Coverage"),
                                            React.createElement(MobileArticleTable, null,
                                                React.createElement("tbody", null, row.posts.map(function (article) {
                                                    return (React.createElement("tr", null,
                                                        React.createElement(ArticleTD, { key: article.id },
                                                            React.createElement("a", { href: "/" + article.slug, dangerouslySetInnerHTML: createMarkup(article.title) }))));
                                                }))))),
                                    row.posts.map(function (article) {
                                        return (React.createElement(ArticleTD, { key: article.id, className: "d-none d-sm-table-cell" },
                                            React.createElement("a", { href: "/" + article.slug, dangerouslySetInnerHTML: createMarkup(article.title) })));
                                    }))) : null;
                            }))))),
                    React.createElement(ToggleTable, { href: "#", onClick: function (e) {
                            e.preventDefault();
                            setToggled(!toggled);
                            setToggleText(toggleText === "Show All Constituents" ? "Show Top Constituents" : "Show All Constituents");
                        } }, toggleText),
                    React.createElement("small", null, "Constituents are subject to change."),
                    React.createElement("div", null,
                        React.createElement("h3", null, "Index Methodology"),
                        React.createElement("p", null, "Each quarter, the Jing Daily Selection Party evaluates and ranks qualified companies through a detailed tiered rating system determined by our (1) Jing Daily Global Luxury Score and (2) Jing Daily Brand Awareness in China Score. Additionally, constituents must meet market cap and daily trading volume requirements to qualify for the Index."),
                        React.createElement("p", null, "Top ranked entities represent companies that have a combination of the highest scores and highest market cap. The Index Score represents the overall health of the global luxury market."),
                        React.createElement("p", null, "For more details about the ranking process, please review our Index Methodology below."),
                        React.createElement(LegalWrap, null,
                            React.createElement(WidgetButton, { href: "https://313ct818yszd3xd6xa2z47nm-wpengine.netdna-ssl.com/wp-content/uploads/2021/01/2020_06_ks_index_selection_Final.pdf" }, "Download Index Methodology")),
                        React.createElement("p", null,
                            "For inquiries please contact ",
                            React.createElement("a", { href: "mailto:index@jingdaily.com" }, "index@jingdaily.com")),
                        React.createElement("h3", null, "Disclaimer"),
                        React.createElement("p", null,
                            "The ",
                            React.createElement("em", null, "Jing Daily"),
                            " KraneShares Global China Luxury Index is prepared from data, public information and independent third party sources which Jing Daily has not verified for accuracy or any recent changes in circumstances not otherwise reflected therein. The information and rankings are not provided, nor should they be considered, as a basis upon which any person or entity should rely in making any investment decisions or for any other purpose."))))),
        React.createElement(Footer, null)));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18;
