var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { WidgetContainer, WidgetTitle } from "./styles";
import styled from "styled-components";
import { ArticleItem } from "@shared";
import { mq } from "@styles";
var MQ = mq;
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 0 auto 48px;\n\n  ", " {\n    margin-bottom: 0;\n  }\n"], ["\n  margin: 0 auto 48px;\n\n  ", " {\n    margin-bottom: 0;\n  }\n"])), MQ.xs);
var Wrap = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 20px;\n  margin: 18px auto 0;\n\n  > div {\n    ", " {\n      .row {\n        width: 100%;\n      }\n      width: 100%;\n      padding: 0;\n    }\n  }\n"], ["\n  display: flex;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 20px;\n  margin: 18px auto 0;\n\n  > div {\n    ", " {\n      .row {\n        width: 100%;\n      }\n      width: 100%;\n      padding: 0;\n    }\n  }\n"])), MQ.xs);
export var RelatedArticles = function (props) {
    return (React.createElement(Container, { className: "container" },
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-12" },
                React.createElement(WidgetContainer, null,
                    React.createElement(WidgetTitle, null, "Related Articles"),
                    React.createElement(Wrap, null, props.related.map(function (postData) {
                        return React.createElement(ArticleItem, { key: postData.id, post: postData, template: "article_footer" });
                    })))))));
};
var templateObject_1, templateObject_2;
