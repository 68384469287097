import React from "react";
import { WidgetContainer, WidgetTitle, WidgetP } from "./styles";
import { ArticleItem } from "@shared";
export var TrendingOnJing = function (props) {
    return (React.createElement(WidgetContainer, null,
        React.createElement(WidgetTitle, null, "Trending On Jing"),
        React.createElement(WidgetP, null, "Our most popular articles you may have missed"),
        props.posts.map(function (postData) {
            return React.createElement(ArticleItem, { key: postData.id, post: postData, template: "sidebar" });
        })));
};
