var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import queryString from "query-string";
import { Endpoints } from "@types";
import { ENV } from "@config";
export var getImgUrl = function (url) {
    return url.replace("jing-daily-media.s3.amazonaws.com", "media.dailyjingjing.com");
};
export var createMarkup = function (__html) {
    return {
        __html: __html
    };
};
export var sanitizeHTML = function (text) {
    return text.replace(/(<([^>]+)>)/gi, "");
};
export var titleize = function (slug) {
    var words = slug.split("-");
    return words
        .map(function (word) {
        return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
    })
        .join(" ");
};
export var useWindowProps = function () {
    var _a = useState({
        width: undefined,
        height: undefined,
        isPortrait: undefined,
        scrollTop: typeof window === "undefined" ? 0 : window.pageYOffset
    }), windowSize = _a[0], setWindowSize = _a[1];
    if (typeof window === "undefined") {
        return windowSize;
    }
    var isPxlDense = window.devicePixelRatio > 1;
    var isTouch = "ontouchstart" in document.documentElement;
    var isPortrait = window.innerHeight > window.innerWidth;
    useEffect(function () {
        function handlWindow() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
                isPortrait: isPortrait && isPxlDense && isTouch,
                scrollTop: window.pageYOffset
            });
        }
        function handleOrientation(m) {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
                isPortrait: m.matches && isPxlDense && isTouch,
                scrollTop: window.pageYOffset
            });
        }
        window.addEventListener("resize", handlWindow);
        window.addEventListener("scroll", handlWindow);
        window.matchMedia("(orientation: portrait)").addListener(handleOrientation);
        handlWindow();
        return function () {
            window.matchMedia("(orientation: portrait)").removeListener(handleOrientation);
            window.removeEventListener("resize", handlWindow);
            window.removeEventListener("scroll", handlWindow);
        };
    }, []);
    return windowSize;
};
export var mutateApi = function () {
    return useMutation(function (_a) {
        var endpoint = _a.endpoint, payload = _a.payload, pathParams = _a.pathParams, queryParams = _a.queryParams, method = _a.method;
        return __awaiter(void 0, void 0, void 0, function () {
            var options, endpointUri, append, response, jsonData;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        options = {
                            method: method
                        };
                        if (method !== "GET") {
                            options = __assign(__assign({}, options), { body: JSON.stringify(payload) });
                        }
                        endpointUri = Endpoints[endpoint];
                        if (pathParams) {
                            Object.entries(pathParams).forEach(function (_a) {
                                var key = _a[0], value = _a[1];
                                endpointUri = endpointUri.replace(":" + key, value);
                            });
                        }
                        append = queryParams && Object.keys(queryParams).length > 0 ? "?" + queryString.stringify(queryParams) : "";
                        return [4 /*yield*/, fetch(ENV.API_HOST + endpointUri + append, options)];
                    case 1:
                        response = _b.sent();
                        if (response.status === 204) {
                            return [2 /*return*/, {}];
                        }
                        return [4 /*yield*/, response.json()];
                    case 2:
                        jsonData = _b.sent();
                        if (!response.ok)
                            throw jsonData;
                        return [2 /*return*/, jsonData];
                }
            });
        });
    });
};
export var useApi = function (endpoint, pathParams, queryParams, finalOpts) {
    var queryOpts = { retry: 1, refetchOnWindowFocus: false };
    if (finalOpts) {
        queryOpts = __assign({}, finalOpts);
    }
    return useQuery([endpoint, { endpoint: endpoint, pathParams: pathParams, queryParams: queryParams }], function () { return __awaiter(void 0, void 0, void 0, function () {
        var options, endpointUri, append, response, jsonData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    options = {
                        method: "GET"
                    };
                    if (!endpoint) {
                        return [2 /*return*/, {}];
                    }
                    endpointUri = endpoint ? Endpoints[endpoint] : "";
                    if (pathParams) {
                        Object.entries(pathParams).forEach(function (_a) {
                            var key = _a[0], value = _a[1];
                            endpointUri = endpointUri.replace(":" + key, value);
                        });
                    }
                    append = queryParams && Object.keys(queryParams).length > 0 ? "?" + queryString.stringify(queryParams) : "";
                    return [4 /*yield*/, fetch(ENV.API_HOST + endpointUri + append, options)];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    jsonData = _a.sent();
                    if (!response.ok)
                        throw new Error(response.statusText);
                    return [2 /*return*/, jsonData];
            }
        });
    }); }, queryOpts);
};
export function omit(target) {
    var omitKeys = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        omitKeys[_i - 1] = arguments[_i];
    }
    return Object.keys(target).reduce(function (res, key) {
        if (!omitKeys.includes(key)) {
            res[key] = target[key];
        }
        return res;
    }, {});
}
