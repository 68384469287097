export var Colors = {
    lightGrey: "#efefef",
    grey: "#f0f0ee",
    medGrey: "#333",
    darkGrey: "#afafaf",
    border: "#dcdcdc",
    inputBg: "#dcdcdc",
    lightText: "#6a6a6a",
    black: "#000",
    white: "#fff",
    blue: "#36c",
    transparent: "transparent"
};
