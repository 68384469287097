var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HomePage, PostPage, CategoryPage, LuxuryIndexPage, ReportPage, ReportPurchasePage, SubscribePage, SpecialPage, HubPage, TagPage, ReportsPage, EventPage, SearchPage, MetaPage, EventsPage } from "@components";
import "isomorphic-fetch";
import { ENV } from "@config";
import queryString from "query-string";
var Routes = [
    {
        path: "/",
        exact: true,
        componentName: HomePage,
        loadData: function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(ENV.API_HOST + "/home-page")];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, {
                                data: data
                            }];
                }
            });
        }); }
    },
    {
        path: "/search",
        componentName: SearchPage,
        loadData: function (path, queryParams) { return __awaiter(void 0, void 0, void 0, function () {
            var search, response, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        search = queryString.parse(window.location.search);
                        return [4 /*yield*/, fetch(ENV.API_HOST + "/posts?_q=" + search._q)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, {
                                data: data
                            }];
                }
            });
        }); }
    },
    {
        path: "/about",
        componentName: MetaPage,
        loadData: function (path, queryParams) { return __awaiter(void 0, void 0, void 0, function () {
            var response, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(ENV.API_HOST + "/metapages?slug=about")];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, {
                                data: data
                            }];
                }
            });
        }); }
    },
    {
        path: "/advertise",
        componentName: MetaPage,
        loadData: function (path, queryParams) { return __awaiter(void 0, void 0, void 0, function () {
            var response, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(ENV.API_HOST + "/metapages?slug=advertising")];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, {
                                data: data
                            }];
                }
            });
        }); }
    },
    {
        path: "/jobs",
        componentName: MetaPage,
        loadData: function (path, queryParams) { return __awaiter(void 0, void 0, void 0, function () {
            var response, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(ENV.API_HOST + "/metapages?slug=jobs")];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, {
                                data: data
                            }];
                }
            });
        }); }
    },
    {
        path: "/jing-daily-team",
        componentName: MetaPage,
        loadData: function (path, queryParams) { return __awaiter(void 0, void 0, void 0, function () {
            var response, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(ENV.API_HOST + "/metapages?slug=jing-daily-team")];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, {
                                data: data
                            }];
                }
            });
        }); }
    },
    {
        path: "/in-the-press",
        componentName: MetaPage,
        loadData: function (path, queryParams) { return __awaiter(void 0, void 0, void 0, function () {
            var response, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(ENV.API_HOST + "/metapages?slug=in-the-press")];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, {
                                data: data
                            }];
                }
            });
        }); }
    },
    {
        path: "/submit-news",
        componentName: MetaPage,
        loadData: function (path, queryParams) { return __awaiter(void 0, void 0, void 0, function () {
            var response, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(ENV.API_HOST + "/metapages?slug=submit-news")];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, {
                                data: data
                            }];
                }
            });
        }); }
    },
    {
        path: "/contact",
        componentName: MetaPage,
        loadData: function (path, queryParams) { return __awaiter(void 0, void 0, void 0, function () {
            var response, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(ENV.API_HOST + "/metapages?slug=contact")];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, {
                                data: data
                            }];
                }
            });
        }); }
    },
    {
        path: "/terms-of-use",
        componentName: MetaPage,
        loadData: function (path, queryParams) { return __awaiter(void 0, void 0, void 0, function () {
            var response, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(ENV.API_HOST + "/metapages?slug=terms-of-use")];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, {
                                data: data
                            }];
                }
            });
        }); }
    },
    {
        path: "/privacy-policy",
        componentName: MetaPage,
        loadData: function (path, queryParams) { return __awaiter(void 0, void 0, void 0, function () {
            var response, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(ENV.API_HOST + "/metapages?slug=privacy-policy")];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, {
                                data: data
                            }];
                }
            });
        }); }
    },
    {
        path: "/jing-daily-reports",
        componentName: ReportsPage,
        loadData: function (_path, queryParams) { return __awaiter(void 0, void 0, void 0, function () {
            var append, response, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        append = queryParams && Object.keys(queryParams).length > 0 ? "?" + queryString.stringify(queryParams) : "";
                        return [4 /*yield*/, fetch(ENV.API_HOST + "/reports" + append)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, {
                                data: data
                            }];
                }
            });
        }); }
    },
    {
        path: "/gucci",
        componentName: HubPage,
        loadData: function (path, queryParams) { return __awaiter(void 0, void 0, void 0, function () {
            var append, response, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        append = queryParams && Object.keys(queryParams).length > 0 ? "?" + queryString.stringify(queryParams) : "";
                        return [4 /*yield*/, fetch(ENV.API_HOST + "/posts/by-tag/gucci" + append)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, {
                                data: data
                            }];
                }
            });
        }); }
    },
    {
        path: "/subscribe/",
        exact: true,
        componentName: SubscribePage,
        loadData: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, {}];
            });
        }); }
    },
    {
        path: "/goat-the-patek-philippe-nautilus/",
        exact: true,
        componentName: SpecialPage,
        loadData: function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(ENV.API_HOST + "/pages/1")];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, {
                                data: data
                            }];
                }
            });
        }); }
    },
    {
        path: "/china-luxury-index/",
        exact: true,
        componentName: LuxuryIndexPage,
        loadData: function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(ENV.API_HOST + "/posts/luxury-index")];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, {
                                data: data
                            }];
                }
            });
        }); }
    },
    {
        path: "/events",
        componentName: EventsPage,
        loadData: function (_path, queryParams) { return __awaiter(void 0, void 0, void 0, function () {
            var append, response, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        append = queryParams && Object.keys(queryParams).length > 0 ? "?" + queryString.stringify(queryParams) : "";
                        return [4 /*yield*/, fetch(ENV.API_HOST + "/events" + append)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, {
                                data: data
                            }];
                }
            });
        }); }
    },
    {
        path: "/event/:slug",
        componentName: EventPage,
        loadData: function (path) { return __awaiter(void 0, void 0, void 0, function () {
            var response, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(ENV.API_HOST + "/events/by-slug/" + path.split("/event/")[1])];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, {
                                data: data
                            }];
                }
            });
        }); }
    },
    {
        path: "/report/:slug",
        componentName: ReportPage,
        loadData: function (path) { return __awaiter(void 0, void 0, void 0, function () {
            var response, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(ENV.API_HOST + "/reports/by-slug/" + path.split("/report/")[1])];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, {
                                data: data
                            }];
                }
            });
        }); }
    },
    {
        path: "/report-checkout/:id",
        componentName: ReportPurchasePage,
        loadData: function (path) { return __awaiter(void 0, void 0, void 0, function () {
            var response, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(ENV.API_HOST + "/reports/" + path.split("/report-checkout/")[1])];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, {
                                data: data
                            }];
                }
            });
        }); }
    },
    {
        path: "/tag/:tag_slug",
        componentName: TagPage,
        loadData: function (path, queryParams) { return __awaiter(void 0, void 0, void 0, function () {
            var append, response, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        append = queryParams && Object.keys(queryParams).length > 0 ? "?" + queryString.stringify(queryParams) : "";
                        return [4 /*yield*/, fetch(ENV.API_HOST + "/posts/by-tag/" + path.split("tag/")[1] + append)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, {
                                data: data
                            }];
                }
            });
        }); }
    },
    {
        path: "/category/:category_slug",
        componentName: CategoryPage,
        loadData: function (path, queryParams) { return __awaiter(void 0, void 0, void 0, function () {
            var append, response, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        append = queryParams && Object.keys(queryParams).length > 0 ? "?" + queryString.stringify(queryParams) : "";
                        return [4 /*yield*/, fetch(ENV.API_HOST + "/posts/by-category/" + path.split("category/")[1] + append)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, {
                                data: data
                            }];
                }
            });
        }); }
    },
    {
        path: "/:slug",
        componentName: PostPage,
        loadData: function (path, queryParams) { return __awaiter(void 0, void 0, void 0, function () {
            var excluded, metaPages, append, response, _a, _b, _c, data;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        excluded = ["", "/", "/gucci", "/jing-daily-reports", "/search"];
                        metaPages = [
                            "/about",
                            "/advertise",
                            "/jobs",
                            "/jing-daily-team",
                            "/in-the-press",
                            "/submit-news",
                            "/contact",
                            "/terms-of-use",
                            "/privacy-policy"
                        ];
                        append = queryParams && Object.keys(queryParams).length > 0 ? "?" + queryString.stringify(queryParams) : "";
                        if (!!excluded.includes(path)) return [3 /*break*/, 5];
                        if (!metaPages.includes(path)) return [3 /*break*/, 2];
                        return [4 /*yield*/, fetch(ENV.API_HOST + "/metapages?slug=" + path.split("/")[1])];
                    case 1:
                        _b = _d.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, fetch(ENV.API_HOST + "/posts/by-slug" + path)];
                    case 3:
                        _b = _d.sent();
                        _d.label = 4;
                    case 4:
                        _a = _b;
                        return [3 /*break*/, 10];
                    case 5:
                        if (!(path === "/search")) return [3 /*break*/, 7];
                        return [4 /*yield*/, fetch(ENV.API_HOST + "/posts?_q=" + (queryParams === null || queryParams === void 0 ? void 0 : queryParams._q))];
                    case 6:
                        _c = _d.sent();
                        return [3 /*break*/, 9];
                    case 7: return [4 /*yield*/, fetch(ENV.API_HOST + "/posts" + append)];
                    case 8:
                        _c = _d.sent();
                        _d.label = 9;
                    case 9:
                        _a = _c;
                        _d.label = 10;
                    case 10:
                        response = _a;
                        return [4 /*yield*/, response.json()];
                    case 11:
                        data = _d.sent();
                        return [2 /*return*/, {
                                data: data
                            }];
                }
            });
        }); }
    }
];
export { Routes };
