var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faTwitter, faLinkedinIn, faWeixin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faRss } from "@fortawesome/free-solid-svg-icons";
import { Colors, mq } from "@styles";
var MQ = mq;
var ContainerWrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  color: #fff;\n"], ["\n  background-color: ", ";\n  color: #fff;\n"])), Colors.medGrey);
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-top: 40px;\n  padding-bottom: 50px;\n"], ["\n  padding-top: 40px;\n  padding-bottom: 50px;\n"])));
var CopyRightWrap = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
var CopyRight = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 14px;\n  line-height: 1.3;\n  color: ", ";\n  margin: 14px auto;\n\n  ", " {\n    margin-bottom: 1.5rem;\n  }\n"], ["\n  font-size: 14px;\n  line-height: 1.3;\n  color: ", ";\n  margin: 14px auto;\n\n  ", " {\n    margin-bottom: 1.5rem;\n  }\n"])), Colors.lightGrey, MQ.xs);
var FooterLogo = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  height: auto;\n  max-width: 230px;\n"], ["\n  width: 100%;\n  height: auto;\n  max-width: 230px;\n"])));
var LinkWrap = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
var AboutNewsWrap = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var JingPoliciesFollowWrap = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var LinkList = styled.ul(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin: 0;\n  padding: 0;\n  width: ", ";\n\n  ", " {\n    width: 50%;\n    margin-bottom: 1.5rem;\n  }\n"], ["\n  margin: 0;\n  padding: 0;\n  width: ", ";\n\n  ", " {\n    width: 50%;\n    margin-bottom: 1.5rem;\n  }\n"])), function (props) { return (props.wider ? "50%" : "33%"); }, MQ.xs);
var LinkItem = styled.li(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  list-style: none;\n  margin: 0 0 5px;\n  padding: 0;\n  a {\n    display: block;\n    text-decoration: none;\n    color: #dcdcdc;\n    font-size: 15px;\n    line-height: 1.2;\n  }\n  svg {\n    min-width: 20px;\n    margin-right: 10px;\n  }\n\n  ", " {\n    margin-bottom: 15px;\n  }\n"], ["\n  list-style: none;\n  margin: 0 0 5px;\n  padding: 0;\n  a {\n    display: block;\n    text-decoration: none;\n    color: #dcdcdc;\n    font-size: 15px;\n    line-height: 1.2;\n  }\n  svg {\n    min-width: 20px;\n    margin-right: 10px;\n  }\n\n  ", " {\n    margin-bottom: 15px;\n  }\n"])), MQ.xs);
var LinkHeadingItem = styled.li(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  color: #36c;\n  margin-top: 30px;\n  margin-bottom: 8px;\n  text-transform: uppercase;\n  letter-spacing: 0.02em;\n  font-weight: 700;\n  font-size: 13px;\n  line-height: 1.2;\n  list-style: none;\n  margin: 0 0 10px;\n  padding: 0;\n"], ["\n  color: #36c;\n  margin-top: 30px;\n  margin-bottom: 8px;\n  text-transform: uppercase;\n  letter-spacing: 0.02em;\n  font-weight: 700;\n  font-size: 13px;\n  line-height: 1.2;\n  list-style: none;\n  margin: 0 0 10px;\n  padding: 0;\n"])));
export var Footer = function (props) {
    return (React.createElement(ContainerWrap, { className: "container-fluid" },
        React.createElement(Container, { className: "container" },
            React.createElement("div", { className: "row" },
                React.createElement(CopyRightWrap, { className: "col-12 col-sm-3" },
                    React.createElement(FooterLogo, { src: "https://media.dailyjingjing.com/assets/logo-white-min.png", alt: "Jing Daily Logo" }),
                    React.createElement(CopyRight, null,
                        "\u00A9 2021 Herlar, LLC. All rights reserved.",
                        React.createElement("br", null),
                        "Jing Daily\u00AE is a registered U.S. trademark of Herlar, LLC.")),
                React.createElement(LinkWrap, { className: "col-12 col-sm-9" },
                    React.createElement("div", { className: "row" },
                        React.createElement(AboutNewsWrap, { className: "col-12 col-sm-5" },
                            React.createElement(LinkList, { wider: true },
                                React.createElement(LinkHeadingItem, null, "About Us"),
                                React.createElement(LinkItem, null,
                                    React.createElement("a", { href: "/about/" }, "About")),
                                React.createElement(LinkItem, null,
                                    React.createElement("a", { href: "/jobs/" }, "Jobs")),
                                React.createElement(LinkItem, null,
                                    React.createElement("a", { href: "/jing-daily-team/" }, "Team")),
                                React.createElement(LinkItem, null,
                                    React.createElement("a", { href: "/in-the-press/" }, "In The Press")),
                                React.createElement(LinkItem, null,
                                    React.createElement("a", { href: "/submit-news/" }, "Write for Us")),
                                React.createElement(LinkItem, null,
                                    React.createElement("a", { href: "/contact/" }, "Contact Us")),
                                React.createElement(LinkItem, null,
                                    React.createElement("a", { href: "/advertise/" }, "Advertise"))),
                            React.createElement(LinkList, { wider: true },
                                React.createElement(LinkHeadingItem, null, "News"),
                                React.createElement(LinkItem, null,
                                    React.createElement("a", { href: "/category/financial-reporting/" }, "Financial Reporting")),
                                React.createElement(LinkItem, null,
                                    React.createElement("a", { href: "/category/market-analysis/" }, "Market Analysis")),
                                React.createElement(LinkItem, null,
                                    React.createElement("a", { href: "/category/consumer-insights/" }, "Consumer Insights")),
                                React.createElement(LinkItem, null,
                                    React.createElement("a", { href: "/category/industry-profiles/" }, "Industry Profiles")),
                                React.createElement(LinkItem, null,
                                    React.createElement("a", { href: "/reports" }, "Reports")),
                                React.createElement(LinkItem, null,
                                    React.createElement("a", { href: "https://jingdaily.com/events/" }, "Events")))),
                        React.createElement(JingPoliciesFollowWrap, { className: "col-12 col-sm-7 flex-wrap" },
                            React.createElement(LinkList, null,
                                React.createElement(LinkHeadingItem, null, "Jing Group"),
                                React.createElement(LinkItem, null,
                                    React.createElement("a", { href: "https://jingculturecommerce.com/" }, "Jing Culture & Commerce")),
                                React.createElement(LinkItem, null,
                                    React.createElement("a", { href: "https://chinafilminsider.com/" }, "China Film Insider"))),
                            React.createElement(LinkList, null,
                                React.createElement(LinkHeadingItem, null, "Policies"),
                                React.createElement(LinkItem, null,
                                    React.createElement("a", { href: "/terms-of-use/" }, "Terms of Use")),
                                React.createElement(LinkItem, null,
                                    React.createElement("a", { href: "/privacy-policy/" }, "Privacy Policy"))),
                            React.createElement(LinkList, null,
                                React.createElement(LinkHeadingItem, null, "Follow Jing Daily"),
                                React.createElement(LinkItem, null,
                                    React.createElement("a", { href: "https://www.facebook.com/jingdaily/", rel: "noreferrer", target: "_blank" },
                                        React.createElement(FontAwesomeIcon, { icon: faFacebookF }),
                                        "Facebook")),
                                React.createElement(LinkItem, null,
                                    React.createElement("a", { href: "https://www.twitter.com/jingdaily/", rel: "noreferrer", target: "_blank" },
                                        React.createElement(FontAwesomeIcon, { icon: faTwitter }),
                                        "Twitter")),
                                React.createElement(LinkItem, null,
                                    React.createElement("a", { href: "https://www.linkedin.com/jingdaily/", rel: "noreferrer", target: "_blank" },
                                        React.createElement(FontAwesomeIcon, { icon: faLinkedinIn }),
                                        "LinkedIn")),
                                React.createElement(LinkItem, null,
                                    React.createElement("a", { href: "https://www.instagram.com/jingdaily/", rel: "noreferrer", target: "_blank" },
                                        React.createElement(FontAwesomeIcon, { icon: faInstagram }),
                                        "Instagram")),
                                React.createElement(LinkItem, null,
                                    React.createElement("a", { href: "weixin://dl/chat?Jing_Daily_China", target: "_blank" },
                                        React.createElement(FontAwesomeIcon, { icon: faWeixin }),
                                        "WeChat")),
                                React.createElement(LinkItem, null,
                                    React.createElement("a", { href: "#", target: "_blank" },
                                        React.createElement(FontAwesomeIcon, { icon: faRss }),
                                        "RSS"))))))))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
