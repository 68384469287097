var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
// @ts-nocheck
import React, { useState } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { TopBar, Nav, Footer, FullNewsletterSignUp, RelatedArticles } from "@shared";
import { AppContainer } from "@containers";
import { mq } from "@styles";
import { createMarkup, getImgUrl } from "@utils";
var MQ = mq;
var ContainerWrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n\n  .row {\n    max-width: calc(100vw);\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n\n  .row {\n    max-width: calc(100vw);\n  }\n"])));
var CoverContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 900px;\n  width: 100%;\n  position: relative;\n  flex-direction: column;\n  overflow: hidden;\n  margin-top: -30px;\n  background-image: url(", ");\n  background-position: top center;\n  background-repeat: no-repeat;\n  background-size: cover;\n\n  ", " {\n    height: 500px;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 900px;\n  width: 100%;\n  position: relative;\n  flex-direction: column;\n  overflow: hidden;\n  margin-top: -30px;\n  background-image: url(", ");\n  background-position: top center;\n  background-repeat: no-repeat;\n  background-size: cover;\n\n  ", " {\n    height: 500px;\n  }\n"])), function (props) { return "" + props.img; }, MQ.xs);
var CoverMask = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 900px;\n  width: 100%;\n  position: absolute;\n  z-index: 1;\n  background-color: rgba(0, 37, 62, 0.5);\n"], ["\n  height: 900px;\n  width: 100%;\n  position: absolute;\n  z-index: 1;\n  background-color: rgba(0, 37, 62, 0.5);\n"])));
var CoverContent = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: 900px;\n  width: 100%;\n  position: absolute;\n  z-index: 2;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  padding: 0 40px;\n\n  ", " {\n    height: 500px;\n  }\n"], ["\n  height: 900px;\n  width: 100%;\n  position: absolute;\n  z-index: 2;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  padding: 0 40px;\n\n  ", " {\n    height: 500px;\n  }\n"])), MQ.xs);
var Title = styled.h1(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 70px;\n  color: #fff;\n  text-align: center;\n\n  ", " {\n    font-size: 40px;\n    color: #fff;\n    text-align: center;\n  }\n\n  ", " {\n    font-size: 32px;\n    color: #fff;\n    text-align: center;\n  }\n"], ["\n  font-size: 70px;\n  color: #fff;\n  text-align: center;\n\n  ", " {\n    font-size: 40px;\n    color: #fff;\n    text-align: center;\n  }\n\n  ", " {\n    font-size: 32px;\n    color: #fff;\n    text-align: center;\n  }\n"])), MQ.md, MQ.xs);
var SubTitle = styled.h2(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 23px;\n  color: #fff;\n  text-align: center;\n\n  ", " {\n    font-size: 20px;\n    color: #fff;\n    text-align: center;\n  }\n\n  ", " {\n    font-size: 16px;\n    color: #fff;\n    text-align: center;\n  }\n"], ["\n  font-size: 23px;\n  color: #fff;\n  text-align: center;\n\n  ", " {\n    font-size: 20px;\n    color: #fff;\n    text-align: center;\n  }\n\n  ", " {\n    font-size: 16px;\n    color: #fff;\n    text-align: center;\n  }\n"])), MQ.md, MQ.xs);
var Author = styled.h4(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 16px;\n  color: #fff;\n\n  ", " {\n    font-size: 13px;\n    color: #fff;\n    text-align: center;\n  }\n"], ["\n  font-size: 16px;\n  color: #fff;\n\n  ", " {\n    font-size: 13px;\n    color: #fff;\n    text-align: center;\n  }\n"])), MQ.xs);
var Container = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-direction: column;\n"], ["\n  display: flex;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-direction: column;\n"])));
var ContentWrap = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  ", " {\n    max-width: calc(100vw - 1.5rem);\n    margin-left: auto;\n    margin-right: auto;\n    margin-bottom: 25px;\n  }\n"], ["\n  ", " {\n    max-width: calc(100vw - 1.5rem);\n    margin-left: auto;\n    margin-right: auto;\n    margin-bottom: 25px;\n  }\n"])), MQ.xs);
var Body = styled.article(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-size: 16px;\n  margin-top: 10px;\n  line-height: 1.5;\n  width: 75%;\n  margin: 30px auto 0;\n  ", " {\n    width: 100%;\n  }\n"], ["\n  font-size: 16px;\n  margin-top: 10px;\n  line-height: 1.5;\n  width: 75%;\n  margin: 30px auto 0;\n  ", " {\n    width: 100%;\n  }\n"])), MQ.xs);
var ImageInterstitial = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  margin: 30px auto 0;\n  width: 100%;\n  color: #333;\n  text-align: center;\n  img {\n    max-width: 100%;\n  }\n  div {\n    font-size: 14px;\n    margin-top: 10px;\n    width: 1154px;\n    margin: 0 auto;\n    text-align: left;\n    ", " {\n      max-width: 100%;\n    }\n    ", " {\n      max-width: 100%;\n    }\n  }\n"], ["\n  margin: 30px auto 0;\n  width: 100%;\n  color: #333;\n  text-align: center;\n  img {\n    max-width: 100%;\n  }\n  div {\n    font-size: 14px;\n    margin-top: 10px;\n    width: 1154px;\n    margin: 0 auto;\n    text-align: left;\n    ", " {\n      max-width: 100%;\n    }\n    ", " {\n      max-width: 100%;\n    }\n  }\n"])), MQ.xs, MQ.md);
var ImageWrap = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  img {\n    width: 50%;\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  img {\n    width: 50%;\n  }\n"])));
var ProductWrap = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  margin-top: 30px;\n  justify-content: center;\n"], ["\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  margin-top: 30px;\n  justify-content: center;\n"])));
var QuoteWrap = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  margin: 30px auto 0;\n  width: 100%;\n  color: #333;\n  h2 {\n    font-size: 65px;\n    margin-top: 10px;\n  }\n\n  ", " {\n    h2 {\n      font-size: 42px;\n      margin-top: 10px;\n    }\n  }\n\n  ", " {\n    h2 {\n      font-size: 26px;\n      margin-top: 10px;\n    }\n  }\n"], ["\n  margin: 30px auto 0;\n  width: 100%;\n  color: #333;\n  h2 {\n    font-size: 65px;\n    margin-top: 10px;\n  }\n\n  ", " {\n    h2 {\n      font-size: 42px;\n      margin-top: 10px;\n    }\n  }\n\n  ", " {\n    h2 {\n      font-size: 26px;\n      margin-top: 10px;\n    }\n  }\n"])), MQ.md, MQ.xs);
var ProductItem = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  img {\n    margin-bottom: 10px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  img {\n    margin-bottom: 10px;\n  }\n"])));
var ProductTitle = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  font-size: 18px;\n  margin-bottom: 10px;\n"], ["\n  font-size: 18px;\n  margin-bottom: 10px;\n"])));
var ProductDesc = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  font-size: 15px;\n  font-style: italic;\n  margin-bottom: 10px;\n"], ["\n  font-size: 15px;\n  font-style: italic;\n  margin-bottom: 10px;\n"])));
var ProductPrice = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  font-size: 18px;\n  font-weight: 700;\n  margin-bottom: 10px;\n"], ["\n  font-size: 18px;\n  font-weight: 700;\n  margin-bottom: 10px;\n"])));
var Product = function (props) {
    var product = props.product;
    var id = product.id, title = product.title, desc = product.desc, price = product.price, image = product.image;
    return (React.createElement(ProductItem, { className: "col-12 col-md-3" },
        React.createElement("img", { src: getImgUrl(image.formats.medium.ur), alt: title }),
        React.createElement(ProductTitle, null, title),
        React.createElement(ProductDesc, null, desc),
        React.createElement(ProductPrice, null, price)));
};
var Interstitial = function (props) {
    var interstitial = props.interstitial;
    var __component = interstitial.__component, id = interstitial.id, caption = interstitial.caption, title = interstitial.title, rich_text = interstitial.rich_text, image = interstitial.image, images = interstitial.images, quote = interstitial.quote, quoter = interstitial.quoter;
    if (__component === "pages.image") {
        return (React.createElement(ImageInterstitial, null,
            React.createElement("img", { src: getImgUrl(image.formats.medium.url), alt: title }),
            React.createElement("div", null, caption)));
    }
    if (__component === "pages.rich-text" && !!rich_text) {
        return React.createElement(Body, { dangerouslySetInnerHTML: createMarkup(rich_text) });
    }
    if (__component === "pages.image-block") {
        return (React.createElement(ImageInterstitial, null,
            React.createElement(ImageWrap, null, images === null || images === void 0 ? void 0 : images.map(function (image) { return (React.createElement("img", { src: getImgUrl(image.formats.medium.url), alt: title })); })),
            React.createElement("div", null, caption)));
    }
    if (__component === "pages.quote") {
        return (React.createElement(QuoteWrap, null,
            React.createElement("h2", null, quote)));
    }
    return null;
};
export var SpecialPage = function (props) {
    var _a, _b, _c, _d, _e;
    var _f = useState(((_b = (_a = props === null || props === void 0 ? void 0 : props.staticContext) === null || _a === void 0 ? void 0 : _a.pageData) === null || _b === void 0 ? void 0 : _b.data.post) || null), data = _f[0], setData = _f[1];
    var _g = useState(((_e = (_d = (_c = props === null || props === void 0 ? void 0 : props.staticContext) === null || _c === void 0 ? void 0 : _c.pageData) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.related) || []), related = _g[0], setRelated = _g[1];
    if (data === null) {
        return null;
    }
    return (React.createElement(AppContainer, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, data.title)),
        React.createElement(TopBar, null),
        React.createElement(Nav, null),
        React.createElement(ContainerWrap, null,
            React.createElement(CoverContainer, { img: data.cover.formats.large.url },
                React.createElement(CoverMask, null),
                React.createElement(CoverContent, null,
                    React.createElement(Title, null, data.title),
                    React.createElement(Author, null, "BY KAREEM RASHED, HASSAN ALI KHAN | FEB 28, 2020"),
                    React.createElement(SubTitle, null, data.subtitle))),
            React.createElement(Container, { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement(ContentWrap, null,
                            !!data.body1 && React.createElement(Body, { dangerouslySetInnerHTML: createMarkup(data.body1) }),
                            data.interstitial1 && data.interstitial1.map(function (inter) { return React.createElement(Interstitial, { interstitial: inter }); }),
                            !!data.body2 && React.createElement(Body, { dangerouslySetInnerHTML: createMarkup(data.body2) }),
                            data.interstitial2 && data.interstitial2.map(function (inter) { return React.createElement(Interstitial, { interstitial: inter }); }),
                            !!data.body3 && React.createElement(Body, { dangerouslySetInnerHTML: createMarkup(data.body3) }),
                            data.interstitial3 && data.interstitial3.map(function (inter) { return React.createElement(Interstitial, { interstitial: inter }); }),
                            !!data.body3 && React.createElement(Body, { dangerouslySetInnerHTML: createMarkup(data.body3) }),
                            data.products && (React.createElement(ProductWrap, { className: "row" }, data.products.map(function (product) { return (React.createElement(Product, { product: product })); }))))))),
            React.createElement(FullNewsletterSignUp, { type: "article" }),
            related && related.length > 0 && React.createElement(RelatedArticles, { related: related })),
        React.createElement(Footer, null)));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18;
