var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import styled from "styled-components";
import { WidgetContainer, WidgetTitle, WidgetP, WidgetSerifP } from "./styles";
import { Colors } from "@styles";
var FormWrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var FormContentWrap = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  flex-direction: column;\n  margin-top: 10px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  flex-direction: column;\n  margin-top: 10px;\n"])));
var InputWrap = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  margin-bottom: 10px;\n"], ["\n  width: 100%;\n  margin-bottom: 10px;\n"])));
var SubmitWrap = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var EmailInput = styled.input(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin: 6px 0 0;\n  appearance: none;\n  width: 100%;\n  padding: 0.75em 10px;\n  font-size: 16px;\n  border: none;\n  border-bottom: 0;\n  border-radius: 0;\n  background: ", ";\n  font-family: \"Quasimoda\", tahoma;\n"], ["\n  margin: 6px 0 0;\n  appearance: none;\n  width: 100%;\n  padding: 0.75em 10px;\n  font-size: 16px;\n  border: none;\n  border-bottom: 0;\n  border-radius: 0;\n  background: ", ";\n  font-family: \"Quasimoda\", tahoma;\n"])), Colors.white);
var Submit = styled.input(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  appearance: none;\n  box-sizing: border-box;\n  width: 100%;\n  display: block;\n  border: none;\n  outline: none;\n  margin-top: 20px;\n  background-color: ", ";\n  color: #fff;\n  font-weight: 600;\n  border-radius: 3px;\n  padding: 10px 16px;\n  text-align: center;\n  font-size: 16px;\n  line-height: 1.45;\n  margin-top: 6px;\n  cursor: pointer;\n  font-family: \"Quasimoda\", tahoma;\n"], ["\n  appearance: none;\n  box-sizing: border-box;\n  width: 100%;\n  display: block;\n  border: none;\n  outline: none;\n  margin-top: 20px;\n  background-color: ", ";\n  color: #fff;\n  font-weight: 600;\n  border-radius: 3px;\n  padding: 10px 16px;\n  text-align: center;\n  font-size: 16px;\n  line-height: 1.45;\n  margin-top: 6px;\n  cursor: pointer;\n  font-family: \"Quasimoda\", tahoma;\n"])), Colors.blue);
export var NewsletterSignUp = function (props) {
    var P = props.type === "finance" ? WidgetSerifP : WidgetP;
    return (React.createElement(WidgetContainer, { darker: true },
        React.createElement(WidgetTitle, null, props.type === "jing-daily"
            ? "Start your day with our newsletter"
            : props.type === "finance"
                ? "Jing Finance Newsletter"
                : "See the future of marketing with the Jing Daily Content Commerce Edition"),
        React.createElement(P, null, props.type === "jing-daily"
            ? "Our daily newsletter delivers insights, analysis, and breaking news from our on-the-ground reporters."
            : props.type === "finance"
                ? "Sign up for insider analysis on the financial trends driving the Index and stay ahead of luxury market shifts in China."
                : "Our twice-weekly newsletter covers the latest developments from China and around the world on how brands use content to drive revenue."),
        React.createElement(FormWrap, null,
            React.createElement("form", { action: "//jingdaily.us1.list-manage.com/subscribe/post?u=555a04b48e1f20aecf5db4d61&id=8dec01cd8d", method: "post", className: "validate sidebar-newsletter-signup js-track-submit", target: "_blank", noValidate: true },
                React.createElement(FormContentWrap, null,
                    React.createElement(InputWrap, null,
                        React.createElement(EmailInput, { type: "email", name: "EMAIL", id: "mce-EMAIL", placeholder: "Your Email" })),
                    React.createElement(SubmitWrap, null,
                        React.createElement(Submit, { type: "submit", value: "Subscribe", name: "subscribe", id: "mc-embedded-subscribe" })))))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
