var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import { TopBar, Nav, Footer, ArticleItem, FeaturedHeroArticle, FullNewsletterSignUp, JingIndex, Events, TrendingOnJing, Reports, StickyNewsletterSignUp, SideBanner, WideBanner } from "@shared";
import { AppContainer } from "@containers";
import Slider from "react-slick";
var ContainerWrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n"])));
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  .row {\n    flex: 1;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  .row {\n    flex: 1;\n  }\n"])));
var SliderWrap = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  overflow: hidden;\n\n  .slick-arrow {\n    display: none;\n  }\n\n  .slick-slider {\n    background: #000;\n    margin: 0 0 25px;\n  }\n\n  .slick-slide .container .row:first-child {\n    margin-left: 0;\n    margin-right: 0;\n  }\n\n  .slick-slide .row > *:first-child {\n    padding: 0 !important;\n  }\n\n  .slick-dots {\n    bottom: 15px;\n\n    li {\n      margin: 0;\n    }\n\n    li button:before {\n      color: #fff;\n    }\n\n    li.slick-active button:before {\n      opacity: 1 !important;\n      color: #fff;\n    }\n  }\n"], ["\n  overflow: hidden;\n\n  .slick-arrow {\n    display: none;\n  }\n\n  .slick-slider {\n    background: #000;\n    margin: 0 0 25px;\n  }\n\n  .slick-slide .container .row:first-child {\n    margin-left: 0;\n    margin-right: 0;\n  }\n\n  .slick-slide .row > *:first-child {\n    padding: 0 !important;\n  }\n\n  .slick-dots {\n    bottom: 15px;\n\n    li {\n      margin: 0;\n    }\n\n    li button:before {\n      color: #fff;\n    }\n\n    li.slick-active button:before {\n      opacity: 1 !important;\n      color: #fff;\n    }\n  }\n"])));
var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: false
};
export var HomePage = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var initialPage = 0;
    if (typeof window !== "undefined") {
        var search = queryString.parse(window.location.search);
        initialPage = search && search.page ? parseInt(search.page, 10) - 1 : 0;
    }
    var pageNum = useState(initialPage)[0];
    var _k = useState(((_c = (_b = (_a = props === null || props === void 0 ? void 0 : props.staticContext) === null || _a === void 0 ? void 0 : _a.pageData) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.homepage) || []), data = _k[0], setData = _k[1];
    var _l = useState(((_f = (_e = (_d = props === null || props === void 0 ? void 0 : props.staticContext) === null || _d === void 0 ? void 0 : _d.pageData) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.trending) || []), trending = _l[0], setTrending = _l[1];
    var _m = useState(((_j = (_h = (_g = props === null || props === void 0 ? void 0 : props.staticContext) === null || _g === void 0 ? void 0 : _g.pageData) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.events) || []), events = _m[0], setEvents = _m[1];
    var handlePagination = function (pageNumber) {
        window.location.href = window.location.pathname + "?page=" + (pageNumber + 1);
    };
    if (!data) {
        return null;
    }
    var posts = data.posts, top_side_posts = data.top_side_posts, featured_post = data.featured_post;
    return (React.createElement(AppContainer, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, "Jing Daily \u2014 The business of luxury in China")),
        React.createElement(TopBar, null),
        React.createElement(Nav, null),
        React.createElement(ContainerWrap, null,
            React.createElement(Container, { className: "container" },
                React.createElement("div", { className: "row align-items-start" },
                    React.createElement("div", { className: "col-12 col-md-8 col-lg-9" }, data && featured_post && React.createElement(FeaturedHeroArticle, { post: featured_post })),
                    React.createElement("div", { className: "col-12 col-md-4 col-lg-3 d-none d-sm-flex flex-column" }, top_side_posts &&
                        top_side_posts.map(function (postData) {
                            return React.createElement(ArticleItem, { key: postData.id, post: postData, template: "featured_sidebar" });
                        })))),
            React.createElement(SliderWrap, { className: "d-sm-none container", style: { overflow: "hidden" } },
                React.createElement(Slider, __assign({}, settings), data &&
                    top_side_posts &&
                    top_side_posts.map(function (postData) {
                        return React.createElement(ArticleItem, { key: postData.id, post: postData, template: "mobile_carousel" });
                    }))),
            React.createElement(FullNewsletterSignUp, { type: "homepage" }),
            React.createElement(Container, { className: "container" },
                React.createElement("div", { className: "row align-items-start" },
                    React.createElement("div", { className: "col-12 col-md-8 col-lg-9" },
                        React.createElement(WideBanner, null),
                        posts &&
                            posts.map(function (postData) {
                                return React.createElement(ArticleItem, { key: postData.id, post: postData, template: "standard" });
                            })),
                    React.createElement("div", { className: "col-12 col-md-4 col-lg-3" },
                        React.createElement(JingIndex, null),
                        React.createElement(SideBanner, null),
                        trending && trending.length > 0 && React.createElement(TrendingOnJing, { posts: trending }),
                        React.createElement(Events, { events: events }),
                        React.createElement(Reports, null))))),
        React.createElement(Footer, null),
        React.createElement(StickyNewsletterSignUp, null)));
};
var templateObject_1, templateObject_2, templateObject_3;
