var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import styled from "styled-components";
var Image = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 25px;\n  width: 100%;\n  display: block;\n"], ["\n  margin-bottom: 25px;\n  width: 100%;\n  display: block;\n"])));
export var WideBanner = function () {
    return React.createElement(Image, { alt: "Jing Daily Report", src: "https://media.dailyjingjing.com/assets/nft-lb-report-min.jpeg" });
};
var templateObject_1;
