var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import styled from "styled-components";
import { createMarkup } from "@utils";
var ContainerWrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  height: 480px;\n  width: 100%;\n  background-image: url(", ");\n  background-position: top center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  position: relative;\n"], ["\n  display: flex;\n  height: 480px;\n  width: 100%;\n  background-image: url(", ");\n  background-position: top center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  position: relative;\n"])), function (props) { return "" + props.img; });
var Mask = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  z-index: 2;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.2);\n"], ["\n  z-index: 2;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.2);\n"])));
var Details = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 45%;\n  height: 100%;\n  padding: 32px 32px 20px;\n  position: absolute;\n  z-index: 3;\n"], ["\n  width: 45%;\n  height: 100%;\n  padding: 32px 32px 20px;\n  position: absolute;\n  z-index: 3;\n"])));
var Category = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 13px;\n  line-height: 15.6px;\n  text-transform: uppercase;\n  letter-spacing: 0.02em;\n  margin-bottom: 10px;\n  font-weight: 700;\n  color: #fff;\n\n  a {\n    color: inherit;\n    text-decoration: none;\n  }\n"], ["\n  font-size: 13px;\n  line-height: 15.6px;\n  text-transform: uppercase;\n  letter-spacing: 0.02em;\n  margin-bottom: 10px;\n  font-weight: 700;\n  color: #fff;\n\n  a {\n    color: inherit;\n    text-decoration: none;\n  }\n"])));
var Title = styled.h2(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin: 0 0 2px;\n  font-weight: 700;\n  font-size: 28px;\n  line-height: 1.3;\n  color: #fff;\n"], ["\n  margin: 0 0 2px;\n  font-weight: 700;\n  font-size: 28px;\n  line-height: 1.3;\n  color: #fff;\n"])));
var Author = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 14px;\n  line-height: 21px;\n  color: #fff;\n"], ["\n  font-size: 14px;\n  line-height: 21px;\n  color: #fff;\n"])));
var TitleLink = styled.a(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: #fff;\n  text-decoration: none;\n"], ["\n  color: #fff;\n  text-decoration: none;\n"])));
export var FeaturedHeroArticle = function (props) {
    var _a;
    var post = props.post;
    return (React.createElement(ContainerWrap, { img: post.featured_image.formats.medium.url, className: "d-none d-sm-flex" },
        React.createElement(Mask, null),
        React.createElement(Details, null,
            React.createElement(Category, null,
                React.createElement("a", { href: "/category/" + post.categories[0].slug }, post.categories[0].name)),
            React.createElement(TitleLink, { href: "/" + post.slug },
                React.createElement(Title, { dangerouslySetInnerHTML: createMarkup(post.title) })),
            ((_a = post === null || post === void 0 ? void 0 : post.author) === null || _a === void 0 ? void 0 : _a.name) && React.createElement(Author, null,
                "By ",
                post.author.name))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
