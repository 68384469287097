var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { WidgetContainer, WidgetTitle, WidgetP, WidgetButton } from "./styles";
import styled from "styled-components";
import { mq } from "@styles";
import { getImgUrl } from "@utils";
var MQ = mq;
var EventBodyWrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  div {\n    border-top: 1px solid #dcdcdc;\n  }\n  span {\n    color: #6a6a6a;\n    font-size: 13px;\n    line-height: 1.3;\n  }\n  h2 {\n    margin: 0;\n    font-family: \"Quasimoda\", tahoma;\n    font-weight: 600;\n    font-size: 13px;\n    line-height: 1.3;\n    padding-top: 10px;\n    ", " {\n      font-size: 15px;\n    }\n    a {\n      color: inherit;\n      text-decoration: none;\n    }\n  }\n"], ["\n  div {\n    border-top: 1px solid #dcdcdc;\n  }\n  span {\n    color: #6a6a6a;\n    font-size: 13px;\n    line-height: 1.3;\n  }\n  h2 {\n    margin: 0;\n    font-family: \"Quasimoda\", tahoma;\n    font-weight: 600;\n    font-size: 13px;\n    line-height: 1.3;\n    padding-top: 10px;\n    ", " {\n      font-size: 15px;\n    }\n    a {\n      color: inherit;\n      text-decoration: none;\n    }\n  }\n"])), MQ.xs);
var BodyWrap = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  p {\n    display: inline;\n  }\n"], ["\n  p {\n    display: inline;\n  }\n"])));
var EventItemWrap = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  img,\n  .row {\n    max-width: 100%;\n  }\n"], ["\n  img,\n  .row {\n    max-width: 100%;\n  }\n"])));
var EventItem = function (_a) {
    var _b;
    var event = _a.event;
    return (React.createElement(EventItemWrap, { className: "row" },
        React.createElement(EventBodyWrap, { className: "col-7" },
            React.createElement("div", null,
                React.createElement("h2", null,
                    React.createElement("a", { href: "/event/" + event.slug }, event.title)),
                React.createElement("span", null, "JUNE 30 @ 10:00 AM - 11:00 AM"))),
        React.createElement("div", { className: "col-5" }, ((_b = event.image) === null || _b === void 0 ? void 0 : _b.url) && (React.createElement("a", { href: "/event/" + event.slug },
            React.createElement("img", { src: getImgUrl(event.image.formats.medium.url), alt: event.title }))))));
};
export var Events = function (props) {
    return (React.createElement(WidgetContainer, null,
        React.createElement(WidgetTitle, null, "Mark It Down"),
        React.createElement(WidgetP, null, "Your source for webinars to podcasts to global industry events. Our Event Calendar has you covered."),
        React.createElement("div", { className: "container" }, props.events.map(function (event) { return (React.createElement(EventItem, { event: event, key: event.id })); })),
        React.createElement(WidgetButton, { href: "/events" }, "View More Events")));
};
var templateObject_1, templateObject_2, templateObject_3;
