var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import styled from "styled-components";
import { Colors } from "@styles";
var ContainerWrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  position: sticky;\n  bottom: 0;\n  z-index: 30;\n  padding: 0.75rem;\n  box-shadow: 1px -12px 5px 0px rgb(238 238 238 / 75%);\n  background-color: ", ";\n  color: ", ";\n  .row {\n    flex: 1;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  position: sticky;\n  bottom: 0;\n  z-index: 30;\n  padding: 0.75rem;\n  box-shadow: 1px -12px 5px 0px rgb(238 238 238 / 75%);\n  background-color: ", ";\n  color: ", ";\n  .row {\n    flex: 1;\n  }\n"])), Colors.medGrey, Colors.white);
var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 5px;\n  font-size: 16px;\n  line-height: 1.2;\n"], ["\n  margin-bottom: 5px;\n  font-size: 16px;\n  line-height: 1.2;\n"])));
var EmailInput = styled.input(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 6px 0 0;\n  appearance: none;\n  width: 100%;\n  padding: 0.75em 10px;\n  font-size: 16px;\n  font-weight: 500;\n  border: none;\n  border-bottom: 0;\n  border-radius: 0;\n  background: ", ";\n  font-family: \"Quasimoda\", tahoma;\n"], ["\n  margin: 6px 0 0;\n  appearance: none;\n  width: 100%;\n  padding: 0.75em 10px;\n  font-size: 16px;\n  font-weight: 500;\n  border: none;\n  border-bottom: 0;\n  border-radius: 0;\n  background: ", ";\n  font-family: \"Quasimoda\", tahoma;\n"])), Colors.white);
var Submit = styled.input(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  appearance: none;\n  box-sizing: border-box;\n  width: 100%;\n  display: block;\n  border: none;\n  outline: none;\n  margin-top: 20px;\n  background-color: ", ";\n  color: #fff;\n  font-weight: 600;\n  border-radius: 3px;\n  padding: 10px 16px;\n  text-align: center;\n  font-size: 16px;\n  line-height: 1.45;\n  margin-top: 6px;\n  cursor: pointer;\n  font-family: \"Quasimoda\", tahoma;\n"], ["\n  appearance: none;\n  box-sizing: border-box;\n  width: 100%;\n  display: block;\n  border: none;\n  outline: none;\n  margin-top: 20px;\n  background-color: ", ";\n  color: #fff;\n  font-weight: 600;\n  border-radius: 3px;\n  padding: 10px 16px;\n  text-align: center;\n  font-size: 16px;\n  line-height: 1.45;\n  margin-top: 6px;\n  cursor: pointer;\n  font-family: \"Quasimoda\", tahoma;\n"])), Colors.blue);
export var StickyNewsletterSignUp = function () {
    return (React.createElement(ContainerWrap, { className: "container d-sm-none flex-column" },
        React.createElement(Title, null,
            "Start your day with the ",
            React.createElement("em", null, "Jing Daily Newsletter")),
        React.createElement("form", { action: "//jingdaily.us1.list-manage.com/subscribe/post?u=555a04b48e1f20aecf5db4d61&id=8dec01cd8d", method: "post", className: "container validate sidebar-newsletter-signup js-track-submit px-0", target: "_blank", noValidate: true },
            React.createElement("div", { className: "row align-items-start" },
                React.createElement("div", { className: "col-8" },
                    React.createElement(EmailInput, { type: "email", name: "EMAIL", id: "mce-EMAIL-Sticky", placeholder: "Your Email" })),
                React.createElement("div", { className: "col-4 ps-0" },
                    React.createElement(Submit, { type: "submit", value: "Subscribe", name: "subscribe", id: "mc-embedded-subscribe-Sticky" }))))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
