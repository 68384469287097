var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import styled from "styled-components";
import { sanitizeHTML } from "@utils";
import moment from "moment";
import { Colors, mq } from "@styles";
import { createMarkup, getImgUrl } from "@utils";
var MQ = mq;
var ContainerWrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: ", ";\n  align-items: flex-start;\n  flex-direction: ", ";\n  margin-bottom: ", ";\n"], ["\n  display: flex;\n  justify-content: ", ";\n  align-items: flex-start;\n  flex-direction: ",
    ";\n  margin-bottom: ",
    ";\n"])), function (props) { return (props.template === "standard" ? "space-between" : "flex-start"); }, function (props) {
    return ["standard", "sidebar", "article_footer"].includes(props.template)
        ? props.template === "sidebar"
            ? "row-reverse"
            : "row"
        : "column";
}, function (props) {
    return ["standard", "hub"].includes(props.template) ? (props.template === "hub" ? "50px" : "30px") : "13px";
});
var Img = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var ItemWrap = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-top: ", ";\n  padding: ", ";\n  background-color: ", ";\n"], ["\n  border-top: ", ";\n  padding: ", ";\n  background-color: ", ";\n"])), function (props) { return (["hub", "mobile_carousel"].includes(props.template) ? 0 : "1px solid #dcdcdc"); }, function (props) { return (props.template === "mobile_carousel" ? "0.75rem 1.5rem" : "10px 0 0"); }, function (props) { return (props.template === "mobile_carousel" ? Colors.black : Colors.transparent); });
var Category = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 10px;\n  text-transform: uppercase;\n  letter-spacing: 0.02em;\n  line-height: 1.45;\n  color: ", ";\n  margin-bottom: 3px;\n  font-weight: 700;\n\n  a {\n    color: inherit;\n    text-decoration: none;\n  }\n"], ["\n  font-size: 10px;\n  text-transform: uppercase;\n  letter-spacing: 0.02em;\n  line-height: 1.45;\n  color: ", ";\n  margin-bottom: 3px;\n  font-weight: 700;\n\n  a {\n    color: inherit;\n    text-decoration: none;\n  }\n"])), function (props) { return (props.template === "mobile_carousel" ? Colors.white : Colors.lightText); });
var TitleLink = styled.a(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: #000;\n  text-decoration: none;\n"], ["\n  color: #000;\n  text-decoration: none;\n"])));
var Title = styled.h2(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin: 0 0 2px;\n  font-weight: 700;\n  font-size: ", ";\n  line-height: 1.3;\n  color: ", ";\n\n  a {\n    color: #000;\n    text-decoration: none;\n  }\n\n  ", " {\n    font-size: ", ";\n    margin-top: ", ";\n  }\n"], ["\n  margin: 0 0 2px;\n  font-weight: 700;\n  font-size: ", ";\n  line-height: 1.3;\n  color: ", ";\n\n  a {\n    color: #000;\n    text-decoration: none;\n  }\n\n  ", " {\n    font-size: ",
    ";\n    margin-top: ", ";\n  }\n"])), function (props) { return (props.template === "sidebar" ? "13px" : "20px"); }, function (props) { return (props.template === "mobile_carousel" ? Colors.white : Colors.black); }, MQ.xs, function (props) {
    return ["sidebar", "article_footer"].includes(props.template)
        ? "13px"
        : props.template === "mobile_carousel"
            ? "20px"
            : "16px";
}, function (props) { return (props.template === "mobile_carousel" ? "10px" : 0); });
var Body = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: ", ";\n  margin-top: 10px;\n"], ["\n  font-size: ", ";\n  margin-top: 10px;\n"])), function (props) { return (props.template === "standard" ? "16px" : "13px"); });
var BodyWrap = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  p {\n    display: inline;\n  }\n"], ["\n  p {\n    display: inline;\n  }\n"])));
var ImgWrap = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  ", " {\n    ", "\n  }\n"], ["\n  ", " {\n    ", "\n  }\n"])), MQ.xs, function (props) { return (["article_footer", "hub"].includes(props.template) ? "margin-right: -12px !important" : ""); });
var ReadMore = styled.a(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  transition: color 0.2s ease-out;\n  color: ", " !important;\n  text-decoration: none;\n  letter-spacing: -0.02em;\n"], ["\n  transition: color 0.2s ease-out;\n  color: ", " !important;\n  text-decoration: none;\n  letter-spacing: -0.02em;\n"])), Colors.lightText);
var Author = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  font-size: 14px;\n  line-height: 21px;\n  color: ", ";\n\n  ", " {\n    font-size: 13px;\n  }\n"], ["\n  font-size: 14px;\n  line-height: 21px;\n  color: ", ";\n\n  ", " {\n    font-size: 13px;\n  }\n"])), Colors.lightText, MQ.xs);
var Date = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  font-size: 11px;\n  line-height: 21px;\n  color: ", ";\n\n  ", " {\n    font-size: 10px;\n  }\n"], ["\n  font-size: 11px;\n  line-height: 21px;\n  color: ", ";\n\n  ", " {\n    font-size: 10px;\n  }\n"])), Colors.lightText, MQ.xs);
export var EventItem = function (props) {
    var event = props.event, template = props.template;
    return (React.createElement(ContainerWrap, { template: template, className: "container " + (template === "featured_sidebar" ? "ps-3 pe-0" : "px-0") },
        React.createElement(ImgWrap, { template: template, className: "row " + (template !== "mobile_carousel" ? (template === "sidebar" ? "ms-0" : "me-0") : "") + " " + (template === "sidebar"
                ? "flex-row-reverse"
                : ["featured_sidebar", "hub", "mobile_carousel"].includes(template)
                    ? "flex-column"
                    : "") },
            ["standard", "sidebar", "article_footer", "hub", "mobile_carousel"].includes(template) && (React.createElement("div", { className: ["featured_sidebar", "hub", "mobile_carousel"].includes(template)
                    ? (template === "hub" ? "px-0" : "") + " col-12"
                    : "col-5" },
                React.createElement("a", { href: "/" + event.slug },
                    React.createElement(Img, { src: getImgUrl(event.image.formats.small.url), alt: event.title })))),
            React.createElement(ItemWrap, { template: template, className: ["featured_sidebar", "hub", "mobile_carousel"].includes(template) ? "col-12" : "col-7" },
                event.categories && event.categories.length > 0 && (React.createElement(Category, { template: template },
                    React.createElement("a", { href: "/category/" + event.categories[0].slug, dangerouslySetInnerHTML: createMarkup(sanitizeHTML(event.categories[0].name)) }))),
                React.createElement(TitleLink, { href: "/" + event.slug },
                    React.createElement(Title, { template: template, dangerouslySetInnerHTML: createMarkup(sanitizeHTML(event.title)) })),
                template === "hub" && React.createElement(Date, null, moment(event.published_at).format("MMMM D, YYYY")),
                !["sidebar", "article_footer", "hub"].includes(template) && (React.createElement(Body, { template: template, className: "d-none d-sm-block" },
                    React.createElement(BodyWrap, { dangerouslySetInnerHTML: createMarkup(sanitizeHTML(event.excerpt)) }),
                    React.createElement(ReadMore, { href: "/" + event.slug }, "Read More")))))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
