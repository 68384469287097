var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { Colors } from "@styles";
export var WidgetContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-direction: column;\n  padding: 18px 18px 23px;\n  margin-bottom: 25px;\n  border: 1px solid ", ";\n  background: ", ";\n\n  h3,\n  h4,\n  p {\n    color: ", ";\n  }\n"], ["\n  display: flex;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-direction: column;\n  padding: 18px 18px 23px;\n  margin-bottom: 25px;\n  border: 1px solid ", ";\n  background: ", ";\n\n  h3,\n  h4,\n  p {\n    color: ", ";\n  }\n"])), Colors.border, function (props) { return (props.darker ? Colors.medGrey : Colors.grey); }, function (props) { return (props.darker ? Colors.white : Colors.black); });
export var WidgetTitle = styled.h3(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: 700;\n  margin: 0;\n  line-height: 1.2;\n  font-size: 22px;\n"], ["\n  font-weight: 700;\n  margin: 0;\n  line-height: 1.2;\n  font-size: 22px;\n"])));
export var WidgetSerifSubtitle = styled.h4(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: 500;\n  margin: 0 0 10px;\n"], ["\n  font-weight: 500;\n  margin: 0 0 10px;\n"])));
export var WidgetSubtitle = styled.h3(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: 10px 0;\n"], ["\n  margin: 10px 0;\n"])));
export var WidgetSerifP = styled.p(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 16px;\n  margin: 16px 0;\n"], ["\n  font-size: 16px;\n  margin: 16px 0;\n"])));
export var WidgetP = styled.p(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 12px;\n  font-weight: 700;\n"], ["\n  font-size: 12px;\n  font-weight: 700;\n"])));
export var WidgetButton = styled.a(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: block;\n  margin-top: 10px;\n  background-color: ", ";\n  color: #fff;\n  text-decoration: none;\n  border-radius: 3px;\n  padding: 10px 16px;\n  text-align: center;\n  font-weight: 600;\n  line-height: 1.45;\n  font-size: 16px;\n  width: 100%;\n"], ["\n  display: block;\n  margin-top: 10px;\n  background-color: ", ";\n  color: #fff;\n  text-decoration: none;\n  border-radius: 3px;\n  padding: 10px 16px;\n  text-align: center;\n  font-weight: 600;\n  line-height: 1.45;\n  font-size: 16px;\n  width: 100%;\n"])), function (props) { return (props.primary ? Colors.blue : Colors.black); });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
