var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { TopBar, Nav, Footer, ArticleItem } from "@shared";
import { titleize } from "@utils";
import { AppContainer } from "@containers";
import { mq } from "@styles";
import ReactPaginate from "react-paginate";
import queryString from "query-string";
var MQ = mq;
var ContainerWrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n"])));
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 90%;\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  flex-direction: column;\n  ", " {\n    width: 100%;\n  }\n  ", " {\n    width: 100%;\n  }\n"], ["\n  width: 90%;\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  flex-direction: column;\n  ", " {\n    width: 100%;\n  }\n  ", " {\n    width: 100%;\n  }\n"])), MQ.md, MQ.xs);
var PostWrap = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr 1fr;\n  gap: 20px;\n  & > div {\n    flex: 0 0 23%;\n  }\n  ", " {\n    & > div {\n      width: 100%;\n      margin: 0 0.75rem 2rem;\n    }\n  }\n"], ["\n  width: 100%;\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr 1fr;\n  gap: 20px;\n  & > div {\n    flex: 0 0 23%;\n  }\n  ", " {\n    & > div {\n      width: 100%;\n      margin: 0 0.75rem 2rem;\n    }\n  }\n"])), MQ.xs);
var TitleHeader = styled.h1(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: 700;\n  font-size: 40px;\n  line-height: 1.2;\n  margin: 0 auto 20px;\n  text-align: center;\n"], ["\n  font-weight: 700;\n  font-size: 40px;\n  line-height: 1.2;\n  margin: 0 auto 20px;\n  text-align: center;\n"])));
var SubTitleHeader = styled.h2(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: 500;\n  font-size: 18px;\n  line-height: 1.2;\n  margin: 0 auto 50px;\n  text-align: left;\n  width: 500px;\n  ", " {\n    margin: 0 auto 35px;\n  }\n  ", " {\n    width: 100%;\n    margin: 0 auto 20px;\n  }\n"], ["\n  font-weight: 500;\n  font-size: 18px;\n  line-height: 1.2;\n  margin: 0 auto 50px;\n  text-align: left;\n  width: 500px;\n  ", " {\n    margin: 0 auto 35px;\n  }\n  ", " {\n    width: 100%;\n    margin: 0 auto 20px;\n  }\n"])), MQ.md, MQ.xs);
export var HubPage = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var initialPage = 0;
    if (typeof window !== "undefined") {
        var search = queryString.parse(window.location.search);
        initialPage = search && search.page ? parseInt(search.page, 10) - 1 : 0;
    }
    var pageNum = useState(initialPage)[0];
    var _k = useState(((_c = (_b = (_a = props === null || props === void 0 ? void 0 : props.staticContext) === null || _a === void 0 ? void 0 : _a.pageData) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.posts) || []), data = _k[0], setData = _k[1];
    var _l = useState(((_f = (_e = (_d = props === null || props === void 0 ? void 0 : props.staticContext) === null || _d === void 0 ? void 0 : _d.pageData) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.tag) || {}), tag = _l[0], setTag = _l[1];
    var handlePagination = function (pageNumber) {
        window.location.href = window.location.pathname + "?page=" + (pageNumber + 1);
    };
    return (React.createElement(AppContainer, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, "Jing Daily \u2014 The business of luxury in China")),
        React.createElement(TopBar, null),
        React.createElement(Nav, null),
        React.createElement(ContainerWrap, null,
            React.createElement(Container, { className: "container-fluid" },
                React.createElement(TitleHeader, null, (tag === null || tag === void 0 ? void 0 : tag.name) ? titleize(tag === null || tag === void 0 ? void 0 : tag.name) : ""),
                React.createElement(SubTitleHeader, null, "Gucci is a luxury fashion house based in Florence, Italy. Its product lines include handbags, ready-to-wear, footwear, and accessories, makeup, fragrances, and home decoration. Gucci was founded in 1921 by Guccio Gucci in Florence, Tuscany."),
                React.createElement(PostWrap, null, data &&
                    data.length > 0 &&
                    data.map(function (postData) {
                        return React.createElement(ArticleItem, { key: postData.id, post: postData, template: "hub" });
                    })),
                typeof window !== "undefined" && (React.createElement(ReactPaginate, { key: "page-hub-" + pageNum, previousLabel: "Previous", nextLabel: "Next", pageCount: ((_j = (_h = (_g = props === null || props === void 0 ? void 0 : props.staticContext) === null || _g === void 0 ? void 0 : _g.pageData) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.totalPages) || 10, marginPagesDisplayed: 0, pageRangeDisplayed: 4, disableInitialCallback: true, onPageChange: function (_a) {
                        var selected = _a.selected;
                        handlePagination(selected);
                    }, initialPage: pageNum, forcePage: pageNum, previousClassName: "page-item", previousLinkClassName: "page-link", nextClassName: "page-item", nextLinkClassName: "page-link", containerClassName: "pagination justify-content-center container-fluid", pageClassName: "page-item", pageLinkClassName: "page-link", activeClassName: "active" })))),
        React.createElement(Footer, null)));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
