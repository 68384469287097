var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { TopBar, Nav, Footer, WidgetButton } from "@shared";
import { AppContainer } from "@containers";
import { Colors, mq } from "@styles";
import { sanitizeHTML } from "@utils";
import ReactPaginate from "react-paginate";
import queryString from "query-string";
import { createMarkup, getImgUrl } from "@utils";
var MQ = mq;
var ContainerWrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n\n  img {\n    max-width: 100%;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n\n  img {\n    max-width: 100%;\n  }\n"])));
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 90%;\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  flex-direction: column;\n  ", " {\n    width: 100%;\n  }\n"], ["\n  width: 90%;\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  flex-direction: column;\n  ", " {\n    width: 100%;\n  }\n"])), MQ.xs);
var TitleHeader = styled.h1(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: 700;\n  font-size: 40px;\n  line-height: 1.2;\n  margin: 0 auto 20px;\n  text-align: center;\n\n  ", " {\n    font-size: 24px;\n    margin: 0 auto 10px;\n  }\n"], ["\n  font-weight: 700;\n  font-size: 40px;\n  line-height: 1.2;\n  margin: 0 auto 20px;\n  text-align: center;\n\n  ", " {\n    font-size: 24px;\n    margin: 0 auto 10px;\n  }\n"])), MQ.xs);
var SubTitleHeader = styled.h2(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: 500;\n  font-size: 18px;\n  line-height: 1.2;\n  margin: 0 auto 50px;\n  text-align: center;\n  max-width: 700px;\n  width: 100%;\n  ", " {\n    width: 100%;\n    margin: 0 auto 20px;\n    text-align: center;\n  }\n"], ["\n  font-weight: 500;\n  font-size: 18px;\n  line-height: 1.2;\n  margin: 0 auto 50px;\n  text-align: center;\n  max-width: 700px;\n  width: 100%;\n  ", " {\n    width: 100%;\n    margin: 0 auto 20px;\n    text-align: center;\n  }\n"])), MQ.xs);
var ReportBodyWrap = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  border-top: 1px solid #dcdcdc;\n  h2 {\n    margin: 0 0 10px;\n    font-family: \"Quasimoda\", tahoma;\n    font-weight: 600;\n    font-size: 20px;\n    line-height: 1.3;\n    padding-top: 10px;\n    ", " {\n      font-size: 15px;\n    }\n    a {\n      color: inherit;\n      text-decoration: none;\n    }\n  }\n"], ["\n  border-top: 1px solid #dcdcdc;\n  h2 {\n    margin: 0 0 10px;\n    font-family: \"Quasimoda\", tahoma;\n    font-weight: 600;\n    font-size: 20px;\n    line-height: 1.3;\n    padding-top: 10px;\n    ", " {\n      font-size: 15px;\n    }\n    a {\n      color: inherit;\n      text-decoration: none;\n    }\n  }\n"])), MQ.xs);
var ReadMore = styled.a(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  transition: color 0.2s ease-out;\n  color: ", " !important;\n  text-decoration: none;\n  letter-spacing: -0.02em;\n"], ["\n  transition: color 0.2s ease-out;\n  color: ", " !important;\n  text-decoration: none;\n  letter-spacing: -0.02em;\n"])), Colors.lightText);
var BodyWrap = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  p {\n    display: inline;\n  }\n"], ["\n  p {\n    display: inline;\n  }\n"])));
var ReportItemWrap = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-bottom: 25px;\n"], ["\n  margin-bottom: 25px;\n"])));
var ReportItem = function (_a) {
    var _b;
    var report = _a.report;
    return (React.createElement(ReportItemWrap, { className: "col-md-6" },
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-5" }, ((_b = report.image) === null || _b === void 0 ? void 0 : _b.url) && (React.createElement("a", { href: "/report/" + report.slug },
                React.createElement("img", { src: getImgUrl(report.image.formats.small.url), alt: report.title })))),
            React.createElement(ReportBodyWrap, { className: "col-7" },
                React.createElement("h2", null,
                    React.createElement("a", { href: "/report/" + report.slug }, report.title)),
                React.createElement("div", { className: "d-sm-block" },
                    React.createElement(BodyWrap, { dangerouslySetInnerHTML: createMarkup(sanitizeHTML(report.excerpt)) }),
                    React.createElement(ReadMore, { href: "/report/" + report.slug }, "Read More")),
                React.createElement(WidgetButton, { href: "/report-checkout/" + report.id }, "Purchase Now")))));
};
export var ReportsPage = function (props) {
    var _a, _b, _c, _d, _e;
    var initialPage = 0;
    if (typeof window !== "undefined") {
        var search = queryString.parse(window.location.search);
        initialPage = search && search.page ? parseInt(search.page, 10) - 1 : 0;
    }
    var pageNum = useState(initialPage)[0];
    var _f = useState(((_b = (_a = props === null || props === void 0 ? void 0 : props.staticContext) === null || _a === void 0 ? void 0 : _a.pageData) === null || _b === void 0 ? void 0 : _b.data.reports) || []), data = _f[0], setData = _f[1];
    var handlePagination = function (pageNumber) {
        window.location.href = window.location.pathname + "?page=" + (pageNumber + 1);
    };
    return (React.createElement(AppContainer, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, "Jing Daily \u2014 The business of luxury in China")),
        React.createElement(TopBar, null),
        React.createElement(Nav, null),
        React.createElement(ContainerWrap, null,
            React.createElement(Container, { className: "container-fluid" },
                React.createElement(TitleHeader, null, "Jing Daily Reports"),
                React.createElement(SubTitleHeader, null, "Jing Daily reports investigate the most important trends shaping the China and global luxury markets today."),
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" }, data &&
                        data.length > 0 &&
                        data.map(function (report) {
                            return React.createElement(ReportItem, { key: report.id, report: report });
                        }))),
                typeof window !== "undefined" && (React.createElement(ReactPaginate, { previousLabel: "Previous", nextLabel: "Next", pageCount: ((_e = (_d = (_c = props === null || props === void 0 ? void 0 : props.staticContext) === null || _c === void 0 ? void 0 : _c.pageData) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.totalPages) || 10, marginPagesDisplayed: 0, pageRangeDisplayed: 4, disableInitialCallback: true, onPageChange: function (_a) {
                        var selected = _a.selected;
                        handlePagination(selected);
                    }, initialPage: pageNum, forcePage: pageNum, previousClassName: "page-item", previousLinkClassName: "page-link", nextClassName: "page-item", nextLinkClassName: "page-link", containerClassName: "pagination justify-content-center container-fluid", pageClassName: "page-item", pageLinkClassName: "page-link", activeClassName: "active" })))),
        React.createElement(Footer, null)));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
